import ChassiRemarcadoImg from "./chassi_remarcado.svg";
import ComunicacaoVendaImg from "./comunicacao_venda.svg";
import DuplicidadeMotorImg from "./duplicidade_motor.svg";
import LeilaoImg from "./leilao.svg";
import MultasDebitosImg from "./multas_debitos.svg";
import OutrosAltertasImg from "./outros_altertas.svg";
import RemarketingImg from "./remarketing.svg";
import RenajudImg from "./renajud.svg";
import RestricaoImg from "./restricao.svg";
import RouboFurtoImg from "./roubo_furto.svg";
import SinistroImg from "./sinistro.svg";
import VendaDiretaImg from "./venda_direta.svg";

export const images = {
  ChassiRemarcadoImg,
  ComunicacaoVendaImg,
  DuplicidadeMotorImg,
  LeilaoImg,
  MultasDebitosImg,
  OutrosAltertasImg,
  RemarketingImg,
  RenajudImg,
  RestricaoImg,
  RouboFurtoImg,
  SinistroImg,
  VendaDiretaImg,
};
