import { IConsultaBaseLeilao } from "../../../../interface/ConsultaBaseLeilao";
import styles from "./styles.module.css";

interface FieldProps {
  label: string;
  value: string;
}

const TextField = ({ label, value }: FieldProps) => {
  return (
    <div className={styles.dadosWrapper}>
      <p className={styles.text}>{label}: </p>&nbsp;
      <p className={`${styles.text} ${styles.value}`}>{`${value || "-"}`}</p>
    </div>
  );
};

export const ConsultaBaseLeilao = (response: IConsultaBaseLeilao) => {
  const { LEILAOXML_1 } = response.RESPOSTA;
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>Detalhes do veículo</p>
      </div>

      <div className={styles.listaWrapper}>
        {LEILAOXML_1 ? (
          <>
            <TextField
              label="Marca"
              value={LEILAOXML_1?.MARCA || "Não informado"}
            />
            <TextField
              label="Modelo"
              value={LEILAOXML_1?.MODELO || "Não informado"}
            />
            <TextField
              label="Ano/Modelo"
              value={LEILAOXML_1?.ANO_MODELO || "Não informado"}
            />
            <TextField
              label="Cor"
              value={LEILAOXML_1?.COR || "Não informado"}
            />
            <TextField
              label="Placa"
              value={LEILAOXML_1?.PLACA || "Não informado"}
            />
            <TextField
              label="Chassi"
              value={LEILAOXML_1?.CHASSI || "Não informado"}
            />
            <TextField
              label="Renavam"
              value={LEILAOXML_1?.RENAVAM || "Não informado"}
            />
            <TextField
              label="Combustível"
              value={LEILAOXML_1?.COMBUSTIVEL || "Não informado"}
            />
            <TextField
              label="Data do Leilão"
              value={LEILAOXML_1?.DATA || "Não informado"}
            />
            <TextField
              label="Lote"
              value={LEILAOXML_1?.LOTE || "Não informado"}
            />
            <TextField
              label="Categoria"
              value={LEILAOXML_1?.CATEGORIA || "Não informado"}
            />
            <TextField
              label="Condição Geral"
              value={LEILAOXML_1?.CONDICAO_GERAL || "Não informado"}
            />
            <TextField
              label="Condição do Motor"
              value={LEILAOXML_1?.COND_MOTOR || "Não informado"}
            />
            <TextField
              label="Condição do Câmbio"
              value={LEILAOXML_1?.COND_CAMBIO || "Não informado"}
            />
            <TextField
              label="Condição do Chassi"
              value={LEILAOXML_1?.COND_CHASSI || "Não informado"}
            />
            <TextField
              label="Quilometragem (KM)"
              value={LEILAOXML_1?.KM || "Não informado"}
            />
            <TextField
              label="Avarias"
              value={LEILAOXML_1?.AVARIAS || "Não informado"}
            />
            <TextField
              label="Comitente"
              value={LEILAOXML_1?.COMITENTE || "Não informado"}
            />
            <TextField
              label="Leiloeiro"
              value={LEILAOXML_1?.LEILOEIRO || "Não informado"}
            />
          </>
        ) : (
          <p>NENHUM REGISTRO ENCONTRADO PARA O DADO INFORMADO</p>
        )}
      </div>
    </div>
  );
};
