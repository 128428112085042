import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { Fragment, ReactNode, useState } from "react";
import { IBacenScoreSCR } from "../../../interface";
import { currencyFormatter, percentFormatter } from "../../../utils/formatters";
import { TextField } from "../../../components/TextField";
import { Score } from "../../../components/Score";
import { ScoreBar } from "../../../components/ScoreBar";

const PercentBodyTemplate = (data: any) => {
  return <p>{percentFormatter(String(data?.PERCENTUAL))}</p>;
};

const CurrencyBodyTemplate = (data: any) => {
  return <p>{currencyFormatter(String(data?.VALOR))}</p>;
};

interface PanelItemProps {
  value?: any;
  title?: ReactNode;
  subtitle?: string;
  headerValue?: string;
  headerPercent?: string;
  items?: any[];
}

const PanelItem = ({
  value,
  title,
  subtitle,
  headerValue,
  headerPercent,
}: PanelItemProps) => {
  return (
    <Panel id="panel-item-bacen-score" header={title}>
      {subtitle && <p>{subtitle}</p>}

      <DataTable
        value={value ?? []}
        size="small"
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column field="DESCRICAO" header="Descrição" />
        <Column
          field="VALOR"
          header={currencyFormatter(headerValue ?? "0.00")}
          body={CurrencyBodyTemplate}
        />
        <Column
          field="PERCENTUAL"
          header={percentFormatter(headerPercent ?? "0.00")}
          body={PercentBodyTemplate}
        />
      </DataTable>
    </Panel>
  );
};

export const BacenScoreSCRPage = (data: IBacenScoreSCR) => {
  const [count, setCount] = useState(0);
  const score = data?.RELATORIO_SCR?.CONSOLIDADO ?? null;
  const operation = data?.RELATORIO_SCR?.OPERACOES ?? null;
  const report = data?.RELATORIO_SCR ?? null;
  const getValue = (value?: string): string => value || "Não informado";

  return (
    <div style={{ width: "98%" }}>
      <Panel header="Cadastral SRC Bacen Score Positivo">
        <Panel header="Dados da Consulta">
          <section style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <div>
              <TextField
                label="Data de Fundação"
                value={getValue(report?.NASCIMENTO_FUNDACAO)}
              />
              <TextField
                label="Data de Início do Relacionamento"
                value={getValue(report?.DATA_INICIO_RELACIONAMENTO)}
              />
              <TextField
                label="Tipo de Documento"
                value={getValue(report?.TIPO_DOCUMENTO)}
              />
            </div>
            <div>
              <TextField
                label="Coobrigação Recebida"
                value={currencyFormatter(report?.COOBRIGACAO_RECEBIDA)}
              />
              <TextField
                label="Percentual de Documentos Processados"
                value={percentFormatter(
                  report?.PERCENTUAL_DOCUMENTOS_PROCESSADOS
                )}
              />
              <TextField
                label="Número de Instituições"
                value={getValue(report?.QUANTIDADE_INSTITUICOES)}
              />
            </div>
          </section>
        </Panel>

        <br />

        <Panel header="SCR Score">
          <div style={{ display: "flex", gap: "40px" }}>
            <div
              style={{
                width: "500px",
                height: "300px",
                overflow: "hidden",
              }}
            >
              <Score
                score={report?.SCORE?.PONTUACAO}
                range={report?.SCORE?.FAIXA}
              />
            </div>

            <ScoreBar
              score={report?.SCORE?.PONTUACAO}
              rangeColor={report?.SCORE?.FAIXA}
            />
          </div>

          <p style={{ fontWeight: "bold" }}>
            IMPORTANTE:{" "}
            <span style={{ fontWeight: "normal", fontStyle: "italic" }}>
              a decisão de aprovação ou não do crédito é de exclusiva
              responsabilidade do concedente. as informações prestadas pelo
              provedor tem como objevo subsidiar essas decisões e, em hipótese
              alguma devem ser ulizadas como justicativa, pelo concedente do
              crédito, para a tomada da referida decisão.
            </span>
          </p>
        </Panel>

        <br />

        {!!data?.RELATORIO_SCR && (
          <>
            <PanelItem
              title="Crédito Vencido"
              value={score?.CREDITO_VENCIDO?.OPERACOES}
              headerValue={score?.CREDITO_VENCIDO?.VALOR}
              headerPercent={score?.CREDITO_VENCIDO?.PERCENTUAL}
            />

            <br />

            <PanelItem
              title="Crédito a Vencer"
              value={score?.CREDITO_AVENCER?.OPERACOES}
              headerValue={score?.CREDITO_AVENCER?.VALOR}
              headerPercent={score?.CREDITO_AVENCER?.PERCENTUAL}
            />

            <br />

            <PanelItem
              title="Limite de Crédito"
              value={score?.LIMITE_CREDITO?.OPERACOES}
              headerValue={score?.LIMITE_CREDITO?.VALOR}
              headerPercent={score?.LIMITE_CREDITO?.PERCENTUAL}
            />

            <br />

            <PanelItem
              title="Prejuizo"
              value={score?.PREJUIZO?.OPERACOES}
              headerValue={score?.PREJUIZO?.VALOR}
              headerPercent={score?.PREJUIZO?.PERCENTUAL}
            />

            <br />

            {operation?.map((item, index) => {
              let code = item?.MODALIDADE?.CODIGO;
              let proxCode = operation[index + 1]?.MODALIDADE?.CODIGO;

              return (
                <Panel key={index} header={item?.MODALIDADE?.DESCRICAO}>
                  <p>{item?.SUB_MODALIDADE?.DESCRICAO}</p>

                  {/* {code === proxCode && } */}
                  <DataTable
                    value={item?.VENCIMENTOS ?? []}
                    size="small"
                    tableStyle={{ minWidth: "50rem" }}
                  >
                    <Column field="DESCRICAO" header="Descrição" />
                    <Column
                      field="VALOR"
                      header={currencyFormatter(item?.TOTAL ?? "0.00")}
                      body={CurrencyBodyTemplate}
                    />
                    <Column
                      field="PERCENTUAL"
                      header={percentFormatter(item?.PERCENTUAL ?? "0.00")}
                      body={PercentBodyTemplate}
                    />
                  </DataTable>
                </Panel>
              );
            })}
          </>
        )}
        {!data?.RELATORIO_SCR && (
          <p>Nenhum dado encontrado para esta consulta</p>
        )}
      </Panel>
    </div>
  );
};
