import { http } from '../../config/axios'

 const findFaturamento = (params:any): Promise<any> => {
  const queryParams = params
                ? Object.keys(params)
                      .map((k) => encodeURIComponent(params[k]))
                      .join('/')
                : '';
  return http
    .get('api/faturamento/find/' + queryParams)
    .then((res) => {
      if(res.data && res.data.faturamentos && res.data.faturamentos.length > 0){
        res.data.faturamentos.forEach((f:any) => {
          f.dataFim = new Date(f.dataFim)
          f.dataInicio = new Date(f.dataInicio)
          f.dataVencimento = new Date(f.dataVencimento)
          f.dataReferencia = new Date(f.dataReferencia)
        });
      }
      return res.data as any
    })
    .catch((error) => {
      console.error('Erro findFaturamento', error)
      throw Error(error.response.data)
    })
}

export default findFaturamento;
