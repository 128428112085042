interface ScoreBarProps {
  score: string;
  rangeColor: string;
}

export const ScoreBar = ({ score, rangeColor }: ScoreBarProps) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "60px",
          background: "linear-gradient(90deg,#DE1616,#FFCA39,#0DAB01)",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p style={{ color: "#fff", fontSize: "1.5rem", fontWeight: "bold" }}>
          Score {score} de 1000
        </p>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ScoreInfo title="Péssimo" score="0 a 200" />
        <ScoreInfo title="Ruim" score="201 a 400" />
        <ScoreInfo title="Regular" score="401 a 600" />
        <ScoreInfo title="Bom" score="601 a 800" />
        <ScoreInfo title="Ótimo" score="801 a 1000" />
      </div>
    </div>
  );
};

interface ScoreInfoProps {
  title: string;
  score: string;
}

const ScoreInfo = ({ title, score }: ScoreInfoProps) => {
  return (
    <div>
      <p style={{ fontWeight: "bold", fontSize: "1.25rem" }}>{title}</p>
      <p style={{ fontWeight: "500" }}>{score}</p>
    </div>
  );
};
