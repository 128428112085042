import { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import findFaturamento from '../../service/findFaturamento';
import { SortOrder } from 'primereact/api';
import { Button } from 'primereact/button';
import postRelatorio from '../../service/postRelatorio';
import { FiltrarRelatorio } from '../../interface/FiltrarRelatorio';
import { Messages } from 'primereact/messages';
import putEstornarFaturamento from '../../service/putEstornarFaturamento';
import { useGlobalState } from '../../components/globalState/GlobalStateProvider';


export default function FaturamentoList() {
  const { t } = useTranslation();
  const [faturamentos, setFaturamentos] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);
  const msgs = useRef<Messages>(null);
  const {state} = useGlobalState();
  
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    idFranquia : state.user.franquiaSelected?.id,
    sortField: 'dataReferencia',
    sortOrder: SortOrder.DESC
  });

  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: (event.first / event.rows) + 1,
      idFranquia : state.user.franquiaSelected?.id,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };
  const onSort = (event:any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      idFranquia : state.user.franquiaSelected?.id,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  const formatDate = (value: { toLocaleDateString: (arg0: string, arg1: { day: string; month: string; year: string; }) => any; }) => {
    return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
  };

  const imprimirInconsistencia = (item:any) => {
    const filtrarRelatorio:FiltrarRelatorio ={tipoRelatorio:'FATURAMENTO_INCONSISTENCIA', tipoArquivo: 'PDF', filtro:{ id:item.id }};
    postRelatorio(filtrarRelatorio).then((data) => {
      if (msgs.current) {
        msgs.current?.clear();
        msgs.current?.show([
            { sticky: false, life: 10000, severity: 'info', summary: 'Sucesso', detail: data, closable: false }
           
        ]);
      }
    });
  }

  const estornar = (item:any) => {
    putEstornarFaturamento(item).then((data) => {
      if (msgs.current) {
        msgs.current?.clear();
        msgs.current?.show([
            { sticky: false, life: 10000, severity: 'info', summary: 'Sucesso', detail: data, closable: false }
           
        ]);
      }
    });
  }

  const actionTemplate = (item:any) => {
    return <>
      { item.status === 'PROCESSADO' && ( <Button icon="pi pi-print" type="button" onClick={(e) => {imprimirInconsistencia(item)}} style={{background:'transparent', color:'gray', border: 'none'}} />)}
      { item.status === 'PROCESSADO' && ( <Button icon="pi pi-trash" type="button" onClick={() => {estornar(item)}} style={{background:'transparent', color:'gray', border: 'none'}} />)}
    </>
  }

  const dateBodyTemplate = (rowData: any, coluna:string) => {
    if(coluna === 'dataFim'){
      return formatDate(rowData.dataFim);
    }
    if(coluna === 'dataInicio'){
      return formatDate(rowData.dataInicio);
    }
    if(coluna === 'dataReferencia'){
      return formatDate(rowData.dataReferencia);
    }

    return formatDate(rowData.dataVencimento);
  };

  const loadLazyData = () => {
    findFaturamento(lazyState).then(data => {setTotalRecords(data.total);setFaturamentos(data.faturamentos)}).catch(() => {setTotalRecords(0);setFaturamentos(undefined)});
  }
  
  useEffect(() => {
    loadLazyData();
  },[lazyState])

  return (
    <div>
      <Messages ref={msgs} />
      <div>
        <DataTable value={faturamentos} first={lazyState.first} dataKey="id" paginator={true}  lazy={true}
                        emptyMessage="Sem registros"
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows} totalRecords={totalRecords} onPage={onPage}
                        tableStyle={{ minWidth: '75rem' }}>
           <Column  field='status' header={t('status')}  />             
           <Column  body={(e) => dateBodyTemplate(e, 'dataReferencia')} header={t('dataReferencia')}  />
           <Column  body={(e) => dateBodyTemplate(e, 'dataVencimento')} header={t('dataVencimento')}  />
           <Column  body={(e) => dateBodyTemplate(e, 'dataInicio')} header={t('dataInicio')}  />
           <Column body={(e) => dateBodyTemplate(e, 'dataFim')} header={t('dataFim')}  />
           <Column body={actionTemplate} />
        </DataTable> 
      </div>
    </div>
  )
}


