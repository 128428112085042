import { BiMenu } from "@react-icons/all-files/bi/BiMenu";
import logo from "../../assets/new-logo-car.png";
import "./Topbar.css";
import { useGlobalState } from "../globalState/GlobalStateProvider";
import { GlobalSaveData } from "../globalState/GlobalSaveData";
import TopbarUserInfo from "./TopbarUserInfo";
import TopbarRelatorio from "./TopbarRelatorio";
import TopbarFranquia from "./TopbarFranquia";

const Topbar = () => {
  const { state } = useGlobalState();
  const handleToggleSidebar = () => {
    GlobalSaveData("sidebarOpen", !state.sidebarOpen);
  };

  return (
    <div id="interface-topbar">
      <div
        style={{
          position: "relative",
          height: "56px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ paddingLeft: "10px" }}>
          <button
            onClick={handleToggleSidebar}
            style={{ background: "transparent", border: "none" }}
          >
            <BiMenu
              style={{ color: "white", fontSize: "30px", cursor: "pointer" }}
            />
          </button>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "150px",
            height: "56px",
            padding: "6px",
            backgroundColor: "white",
          }}
        >
          <img src={logo} alt="Logo" />
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        {!state.user.franquia &&
          state.user.funcoes.includes("FRANQUIA_ADM") && <TopbarFranquia />}
        <TopbarRelatorio />
        <TopbarUserInfo />
      </div>
    </div>
  );
};

export default Topbar;
