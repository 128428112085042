import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface ScoreProps {
  score: string;
  range: string;
}

export const Score = ({ score, range }: ScoreProps) => {
  const colors = (value?: string): string => {
    return {
      OTIMO: "#0DAB01",
      BOM: "#0DAB01",
      REGULAR: "#FFCA39",
      RUIM: "#DE1616",
      PESSIMO: "#DE1616",
    }[value || "RUIM"]!;
  };

  const options = {
    chart: {},
    title: {
      text: score + "",
      align: "center",
      verticalAlign: "middle",
      y: 90,
      style: {
        fontSize: "40px",
        color: colors(range),
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        dataLabels: {
          enabled: false,
          distance: -50,
          style: {
            fontWeight: "bold",
            color: "white",
          },
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "72%"],
      },
    },

    series: [
      {
        type: "pie",
        name: "",
        innerSize: "60%",
        data: [
          {
            name: "",
            y: 375,
            color: colors(range),
            selected: true,
            showInLegend: false,
          },
          {
            name: "",
            y: 1000 - Number(score),
            color: "#ccc",
            selected: true,
            showInLegend: false,
          },
        ],
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
