  
  import {MenuItemInterface} from "../interface/MenuItemInterface"

  const menuItems : MenuItemInterface[] = [
    {
      label: "home",
      icon: 'pi-home',
      url: "/home",
      permission: "HOME",
      hasDivider: false
    },
    {
      label: "consultaCredito",
      icon: 'pi-search',
      url: "/consultar",
      permission: "CONSULTAR",
      hasDivider: false
    },
    {
      label: "registro",
      icon: 'pi-id-card',
      url: "/registro",
      permission: "REGISTRO_VISUALIZAR",
      hasDivider: false
    },
    {
      label: "historicoConsulta",
      icon: ' pi-history',
      url: "/historico",
      permission: "CONSULTA_VISUALIZAR",
      hasDivider: true
    },
    {
      label: "relatorioSinteticoDeConsultas",
      icon: ' pi-filter',
      url: "/filtrarconsultasintetica",
      permission: "RELATORIO_CONSULTA_SINTETICA",
      hasDivider: false
    },
    {
      label: "relatorioApuracaoBilhete",
      icon: ' pi-filter',
      url: "/filtrarapuracaobilhete",
      permission: "RELATORIO_APURACAO_BILHETE",
      hasDivider: false
    },
    {
      label: "faturamento",
      icon: 'pi-wallet',
      url: "/faturamento",
      permission: "FATURAMENTO_VISUALIZAR",
      hasDivider: true
    },
    {
      label: "fatura",
      icon: 'pi-dollar',
      url: "/fatura",
      permission: "FATURA_VISUALIZAR",
      hasDivider: false
    },
    {
      label: "servico",
      icon: 'pi-server',
      url: "/servico",
      permission: "SERVICO_VISUALIZAR",
      hasDivider: false
    },
    {
      label: "plano",
      icon: 'pi-briefcase',
      url: "/plano",
      permission: "PLANO_VISUALIZAR",
      hasDivider: false
    },
    {
      label: "capturaLeilao",
      icon: 'pi-car',
      url: "/leilao",
      permission: "LEILAO_VISUALIZAR",
      hasDivider: true
    },
    {
      label: "capturaKM",
      icon: 'pi-truck',
      url: "/veiculo",
      permission: "VEICULO_VISUALIZAR",
      hasDivider: false
    },
    {
      label: "comunicadoVendaVeiculo",
      icon: 'pi-shopping-cart',
      url: "/veiculovenda",
      permission: "VEICULO_VENDA_VISUALIZAR",
      hasDivider: false
    },
    {
      label: "usuario",
      icon: 'pi-users',
      url: "/usuario",
      permission: "USUARIO_VISUALIZAR",
      hasDivider: true
    },
    {
      label: "franquia",
      icon: 'pi-building',
      url: "/franquia",
      permission: "FRANQUIA_VISUALIZAR",
      hasDivider: false
    },
    {
      label: "cliente",
      icon: 'pi-tag',
      url: "/cliente",
      permission: "CLIENTE_VISUALIZAR",
      hasDivider: false
    },
    {
      label: "permissao",
      icon: 'pi-code',
      url: "/permissao",
      permission: "PERMISSAO_VISUALIZAR",
      hasDivider: false
    },
    {
      label: "parametrosAplicacao",
      icon: 'pi-cog',
      url: "/parametros",
      permission: "PARAMETRO_VISUALIZAR",
      hasDivider: false
    }
    
  ];
 
  export default menuItems;
  