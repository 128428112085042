import { http } from '../../config/axios'

export default function postFaturamento(faturamento: any): Promise<string> {
  return http
    .post('api/faturamento', faturamento)
    .then((res) => {
      return res.data as string
    })
    .catch((error) => {
      console.error('Erro post Faturamento', error)
      throw Error(error.response.data)
    })
}
