export const openPDF = (base64Data: any) => {
  if (!base64Data) return; // Evita erro se o campo estiver vazio

  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }
  const byteArray = new Uint8Array(byteArrays);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  const url = URL.createObjectURL(blob);
  window.open(url, "_blank", "noopener,noreferrer");
};
