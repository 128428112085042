export const currencyFormatter = (currency?: string) => {
  const formatted = currency?.replace(",", ".");

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(Number(formatted));
};

export const percentFormatter = (percent?: string) => {
  const formatted = percent?.replace(",", ".");
  const value = Number(formatted) / 100;

  return new Intl.NumberFormat("pt-BR", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};
