import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { Controller, useForm} from 'react-hook-form';
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useEffect, useRef, useState } from "react";
import { InputMask } from "primereact/inputmask";
import { Stepper } from 'primereact/stepper';
import { StepperPanel, StepperPanelContext, StepperPanelProps } from 'primereact/stepperpanel';
import { Dropdown } from "primereact/dropdown";
import getEstado from "../../service/getEstado";
import getMunicipio from "../../service/getMunicipio";
import getVeiculoVendaTipoSolcitante from "../../service/getVeiculoVendaTipoSolicitante/getVeiculoVendaTipoSolicitanteService";
import { InputNumber } from "primereact/inputnumber";
import getVeiculoVendaTipoDocumentoVeiculo from "../../service/getVeiculoVendaTipoDocumentoVeiculo";
import getVeiculoVendaTipoPessoa from "../../service/getVeiculoVendaTipoPessoa";
import getViaCep from "../../service/getViaCep";
import { Divider } from "primereact/divider";
import { Chip } from "primereact/chip";
import { Checkbox } from "primereact/checkbox";
import { isValidCPF, isValidCNPJ, isValidRenavam } from "../../utils/ValidationUtils";

export default function VeiculoVendaCadastrar({veiculoVenda, onClose, onSave, msgs}:{veiculoVenda:any, onClose: () => void, onSave: (veiculoVenda:any, enviar:boolean) => void, msgs:any}) {
  const { t } = useTranslation();
  const stepperRef = useRef<any>(null);
  const [tiposSolicitante, setTiposSolicitante] = useState<any>([]);
  const [tiposPessoa, setTiposPessoa] = useState<any>([]);
  const [tiposDocumentoVeiculo, setTiposDocumentoVeiculo] = useState<any>([]);
  const [estados, setEstados] = useState<any>([]);
  const [municipios, setMunicipios] = useState<any>([]);
  const [estadoFilter, setEstadoFilter] = useState<string|null>(null);
  const [tipoPessoaProprietario, setTipoPessoaProprietario] = useState<string|null>(null);
  const [tipoPessoaComprador, setTipoPessoaComprador] = useState<string|null>(null);
  const [passo1, setPasso1] = useState<any>()
  const [passo2, setPasso2] = useState<any>()
  const [passo3, setPasso3] = useState<any>()
  const [passo4, setPasso4] = useState<any>()
  const [venda, setVenda] = useState<any>({})
  const [conferencia, setConferencia] = useState<boolean|undefined>(false);
  const [conferenciaData, setConferenciaData] = useState<boolean|undefined>(false);
  const [conferenciaDocumento, setConferenciaDocumento] = useState<boolean|undefined>(false);

  const defaultValues = {
    placa: '',
    renavam: '',
    crv: '',
    dataFirma: null,
    tipoSolicitante: null,
    tipoDocumentoVeiculo: null,
    dataVenda: null,
    dataEmissaoCrv: null,
    valor: null,
    estado: null,
    ufIdentidadeComprador: null,
    estadoCrv: null,
    municipio: null,
    anoFabricacao: null,
    anoModelo: null,
    codigoCrv: null, 
    nomeProprietario: '',
    cepComprador: '',
    bairroComprador: '',
    logradouroComprador: '',
    numeroComprador: '',
    ufComprador: '',
    cidadeComprador: '',
    complementoComprador: '',
    cepProprietario: '',
    bairroProprietario: '',
    logradouroProprietario: '',
    numeroIdentidadeComprador: '',
    orgaoIdentidadeComprador: '',
    dataNascimentoComprador: null,
    nomeMaeComprador: '',
    sexoComprador: null,
    numeroProprietario: '',
    ufProprietario: '',
    cidadeProprietario: '',
    complementoProprietario: '',
    emailProprietario: '',
    documentoProprietario: null,
    tipoPessoaProprietario: null,
    nomeComprador: '',
    emailComprador: '',
    telefoneComprador: '',
    documentoComprador: null,
    tipoPessoaComprador: null,
    viaCrv: null,
    telefoneProprietario: '',
    modelo: ''
  };

  const sexos = [
    { name: 'Selecione', code: null },
    { name: 'Masculino', code: 'M' },
    { name: 'Feminino', code: 'F' }
];
 
  const formatDate = (value: { toLocaleDateString: (arg0: string, arg1: { day: string; month: string; year: string; }) => any; }) => {
    return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
  };
  
  const {
      control,
      formState: { errors},
      handleSubmit,
      setValue
  } = useForm({ defaultValues });

  const onSubmit = (data:any) => {
      setPasso1(data);
      if(stepperRef && stepperRef.current){
        stepperRef.current.nextCallback()
      }
      
  };

  const validRenavam = (renavam:string) => {
    if(isValidRenavam(renavam)){
      msgs.current?.clear();
    }
    else{
      msgs.current?.clear();
      msgs.current?.show([
         { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Renavam inválido', closable: false }
             
      ]);
    }
  }

  const validDocument = (document:string) => {
    document = document.replaceAll('.','').replaceAll('-','').replaceAll('/','');
    let valid = true
    if(document.length == 11){
      valid = isValidCPF(document)
    }
    else{
      valid = isValidCNPJ(document)
    }
    if(valid){
      msgs.current?.clear();
    }
    else{
      msgs.current?.clear();
      msgs.current?.show([
         { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Documento inválido', closable: false }
             
      ]);
    }
  }

  const onSubmit2 = (data:any) => {
    setPasso2(data);
    if(stepperRef && stepperRef.current){
      stepperRef.current.nextCallback()
    }
    
};

const onSubmit3 = (data:any) => {
  setPasso3(data);
  if(stepperRef && stepperRef.current){
    stepperRef.current.nextCallback()
  }
  
};

const onSubmit4 = (data:any) => {
  setPasso4(data);
  if(stepperRef && stepperRef.current){
    setTimeout(()=>populateVenda(data),200);
  }
  
};

const populateVenda = (passo4:any) => {
  setVenda({
    id: veiculoVenda.id? veiculoVenda.id : undefined,
    tipoSolicitante : passo1.tipoSolicitante,
    dataVenda: passo1.dataVenda,
    valor: passo1.valor,
    municipio: passo1.municipio.id,
    municipioNome: passo1.municipio.nome,
    dataFirma: passo1.dataFirma,
    placa: passo2.placa,
    renavam: passo2.renavam,
    tipoDocumentoVeiculo: passo2.tipoDocumentoVeiculo,
    crv: passo2.crv,
    dataEmissaoCrv: passo2.dataEmissaoCrv,
    estadoCrv: passo2.estadoCrv,
    viaCrv: passo2.viaCrv,
    codigoCrv: passo2.codigoCrv,
    anoModelo: passo2.anoModelo,
    anoFabricacao: passo2.anoFabricacao,
    tipoPessoaProprietario: passo3.tipoPessoaProprietario,
    documentoProprietario: passo3.documentoProprietario.replaceAll('.','').replaceAll('-','').replaceAll('/',''),
    nomeProprietario: passo3.nomeProprietario,
    emailProprietario: passo3.emailProprietario,
    cepProprietario: passo3.cepProprietario,
    bairroProprietario: passo3.bairroProprietario,
    logradouroProprietario: passo3.logradouroProprietario,
    numeroEnderecoProprietario: passo3.numeroProprietario,
    ufProprietario: passo3.ufProprietario,
    cidadeProprietario: passo3.cidadeProprietario,
    telefoneProprietario: passo3.telefoneProprietario,
    complementoProprietario: passo4.complementoProprietario,
    tipoPessoaComprador: passo4.tipoPessoaComprador,
    numeroIdentidadeComprador: passo4.numeroIdentidadeComprador,
    documentoComprador: passo4.documentoComprador.replaceAll('.','').replaceAll('-','').replaceAll('/',''),
    ufIdentidadeComprador: passo4.ufIdentidadeComprador,
    nomeComprador: passo4.nomeComprador,
    emailComprador: passo4.emailComprador,
    cepComprador: passo4.cepComprador,
    orgaoIdentidadeComprador: passo4.orgaoIdentidadeComprador,
    bairroComprador: passo4.bairroComprador,
    logradouroComprador: passo4.logradouroComprador,
    numeroEnderecoComprador: passo4.numeroComprador,
    ufComprador: passo4.ufComprador,
    cidadeComprador: passo4.cidadeComprador,
    telefoneComprador: passo4.telefoneComprador,
    sexoComprador: passo4.sexoComprador,
    nomeMaeComprador: passo4.nomeMaeComprador,
    identidadeComprador: passo4.identidadeComprador,
    orgaoIdentidade: passo4.orgaoIdentidade,
    ufIdentidade: passo4.ufIdentidade,
    dataNascimentoComprador : passo4.dataNascimentoComprador,
    complementoComprador: passo4.complementoComprador
  })
  stepperRef.current.nextCallback();
}

const buscarCep = (cep:string, comprador:boolean) => {
  if(cep.length == 8){
    getViaCep(cep).then((data) => {
      if(data){
        if(comprador){
          setValue('bairroComprador', data.bairro) 
          setValue('cidadeComprador', data.localidade)
          setValue('ufComprador', data.uf)
          setValue('logradouroComprador', data.logradouro)
        }
        else{
          setValue('bairroProprietario', data.bairro) 
          setValue('cidadeProprietario', data.localidade)
          setValue('ufProprietario', data.uf)
          setValue('logradouroProprietario', data.logradouro)
        }
        
      }
      
    }).catch((erro) => console.log(erro))
  }
  
}
 
  const save = (enviar:boolean) => {
    window.scrollTo(0, 0);
    onSave(venda, enviar);
  }


  const getFormErrorMessage = (name:string) => {
    type ObjectKey = keyof typeof errors;
    const error = errors[Object.keys(errors).find(k => k === name) as ObjectKey]
    return error ? <small className="p-error">{error.message}</small> : <small className="p-error">&nbsp;</small>;
  };

  useEffect(() => {
    getEstado().then( (data) => setEstados(data))
    getVeiculoVendaTipoSolcitante().then( (data) => setTiposSolicitante(data))
    getVeiculoVendaTipoDocumentoVeiculo().then((data) => setTiposDocumentoVeiculo(data))
    getVeiculoVendaTipoPessoa().then((data) => setTiposPessoa(data))
    if(veiculoVenda.id){
      console.log(veiculoVenda)
      setValue('tipoSolicitante', veiculoVenda.tipoSolicitante.toUpperCase());
      setValue('dataVenda', veiculoVenda.dataVenda);
      setValue('valor', veiculoVenda.valor);
      setValue('dataFirma', veiculoVenda.dataFirma);
      setValue('estado', veiculoVenda.estado);
      setEstadoFilter(veiculoVenda.estado);
      getMunicipio({uf:veiculoVenda.estado}).then((data) => {setMunicipios(data);setValue('municipio', data.filter((m:any) => m.id == veiculoVenda.municipio)[0]);});
      setValue('placa', veiculoVenda.placa.replace('-',''));
      setValue('renavam', veiculoVenda.renavam);
      setValue('crv', veiculoVenda.crv);
      setValue('tipoDocumentoVeiculo', veiculoVenda.tipoDocumentoVeiculo);
      setValue('dataEmissaoCrv', veiculoVenda.dataEmissaoCrv);
      setValue('estadoCrv', veiculoVenda.estadoCrv);
      setValue('viaCrv', veiculoVenda.viaCrv);
      setValue('codigoCrv', veiculoVenda.codigoCrv);
      setValue('anoFabricacao', veiculoVenda.anoFabricacao);
      setValue('anoModelo', veiculoVenda.anoModelo);
      setValue('tipoPessoaComprador', veiculoVenda.tipoPessoaComprador);
      setValue('tipoPessoaProprietario', veiculoVenda.tipoPessoaProprietario);
      setTipoPessoaComprador(veiculoVenda.tipoPessoaComprador);
      setTipoPessoaProprietario(veiculoVenda.tipoPessoaProprietario);
      setValue('documentoComprador', veiculoVenda.documentoComprador.replaceAll('.', '').replaceAll('-',''));
      setValue('documentoProprietario', veiculoVenda.documentoProprietario);
      setValue('nomeMaeComprador', veiculoVenda.nomeMaeComprador);
      setValue('nomeProprietario', veiculoVenda.nomeProprietario);
      setValue('nomeComprador', veiculoVenda.nomeComprador);
      setValue('dataNascimentoComprador', veiculoVenda.dataNascimentoComprador);
      setValue('numeroComprador', veiculoVenda.numeroEnderecoComprador);
      setValue('numeroProprietario', veiculoVenda.numeroEnderecoProprietario);
      setValue('numeroIdentidadeComprador', veiculoVenda.numeroIdentidadeComprador);
      setValue('orgaoIdentidadeComprador', veiculoVenda.orgaoIdentidadeComprador);
      setValue('emailComprador', veiculoVenda.emailComprador);
      setValue('emailProprietario', veiculoVenda.emailProprietario);
      setValue('logradouroComprador', veiculoVenda.logradouroComprador);
      setValue('logradouroProprietario', veiculoVenda.logradouroProprietario);
      setValue('bairroComprador', veiculoVenda.bairroComprador);
      setValue('bairroProprietario', veiculoVenda.bairroProprietario);
      setValue('cepComprador', veiculoVenda.cepComprador);
      setValue('cepProprietario', veiculoVenda.cepProprietario);
      setValue('cidadeComprador', veiculoVenda.cidadeComprador);
      setValue('cidadeProprietario', veiculoVenda.cidadeProprietario);
      setValue('complementoComprador', veiculoVenda.complementoComprador);
      setValue('complementoProprietario', veiculoVenda.complementoProprietario);
      setValue('ufComprador', veiculoVenda.ufComprador);
      setValue('ufProprietario', veiculoVenda.ufProprietario);
      setValue('telefoneComprador', veiculoVenda.telefoneComprador);
      setValue('telefoneProprietario', veiculoVenda.telefoneProprietario);
      setValue('ufIdentidadeComprador', veiculoVenda.ufIdentidadeComprador);
      setValue('sexoComprador', veiculoVenda.sexoComprador);
    }
  }, [])
  

  return (
    <>
      <Stepper ref={stepperRef} linear headerPosition="bottom">
        <StepperPanel  header="Local/Data">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
            <div>
              <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="tipoSolicitante"
                          control={control}
                          rules={{ required: 'Solicitante é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.tipoSolicitante })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="tipoSolicitante" className="font-bold block mb-2 required">{t('solicitante')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => field.onChange(e.value)} options={tiposSolicitante} optionLabel="label" placeholder="Selecione Solicitante" 
                                  filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col">
                    <Controller
                      name="dataFirma"
                      control={control}
                      rules={{ required: 'Data Firma é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.dataFirma })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="firma" className="font-bold block mb-2 required">{t('dt_firma')} </label>
                                  <Calendar maxDate={new Date()} id='data' value={field.value}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                />
                    
                  
                  </div>
              </div>
              <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="dataVenda"
                          control={control}
                          rules={{ required: 'Data Venda é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.dataVenda })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="dataVenda" className="font-bold block mb-2 required">{t('dataVenda')} </label>
                                    <Calendar maxDate={new Date()} id='dataVenda' value={field.value}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="valor"
                          control={control}
                          rules={{ required: 'Valor é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.valor })}></label>
                                  <span className="">
                                  <div>
                                      <label htmlFor="valor" className="font-bold block mb-2 required">{t('valorVeiculo')} </label>
                                      <InputNumber style={{width: '100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
                                  </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                  </div>
                  
                </div>
                <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="estado"
                          control={control}
                          rules={{ required: 'Estado é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.estado })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="estado" className="font-bold block mb-2 required">{t('uf')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => {getMunicipio({uf:e.value}).then((data) => setMunicipios(data)); setEstadoFilter(e.value); field.onChange(e.value)}} options={estados} optionLabel="nome" optionValue="sigla" placeholder="Selecione Estado" 
                                  filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="municipio"
                          control={control}
                          rules={{ required: 'Município é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.municipio })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="municipio" className="font-bold block mb-2 required">{t('cidade')} </label>
                                    <Dropdown disabled={!estadoFilter} style={{width: '50%'}} value={field.value}  onChange={(e) => field.onChange(e.value)} options={municipios} optionLabel="nome" placeholder="Selecione Cidade" 
                                    filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                  </div>
              </div>
              
              
            </div>
            <br></br>
            <br></br>
          
            <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
                <Button icon="pi pi-arrow-right" style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Avançar" type="submit"   />
                
            </div> 
        </form>
        </StepperPanel>
        <StepperPanel header="Dados do Veículo">
          <form onSubmit={handleSubmit(onSubmit2)} className="flex flex-column gap-2">
            <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="placa"
                          control={control}
                          rules={{ required: 'Plca é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.placa })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="placa" className="font-bold block mb-2 required">{t('placa')} </label>
                                    <InputMask id={field.name}  value={field.value} className={classNames({ 'p-invalid': fieldState.error })} mask="aaa-9*99" onChange={(e) => field.onChange(e.target.value)}></InputMask>
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="renavam"
                          control={control}
                          rules={{ required: 'Renavam é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.renavam })}></label>
                                  <span className="">
                                  <div>
                                      <label htmlFor="renavam" className="font-bold block mb-2 required">{t('renavam')} </label>
                                      <InputText onBlur={(e) => validRenavam(e.target.value)} style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                  </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                  </div>
            </div>
            <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="tipoDocumentoVeiculo"
                          control={control}
                          rules={{ required: 'Tipo Documento Veículo é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.tipoDocumentoVeiculo })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="tipoDocumentoVeiculo" className="font-bold block mb-2 required">{t('tipoDocumentoVeiculo')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => field.onChange(e.value)} options={tiposDocumentoVeiculo} optionLabel="label" placeholder="Selecione Tipo Documento Veículo" 
                                  filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="crv"
                          control={control}
                          rules={{ required: 'CRV é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.crv })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="crv" className="font-bold block mb-2 required">{t('crv')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                    
                  
                  </div>
              </div>
              <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                  <Controller
                          name="dataEmissaoCrv"
                          control={control}
                          rules={{ required: 'Data Venda é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.dataEmissaoCrv })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="dataEmissaoCrv" className="font-bold block mb-2 required">{t('dataEmissaoCrv')} </label>
                                    <Calendar maxDate={new Date()} id='dataEmissaoCrv' value={field.value}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="estadoCrv"
                          control={control}
                          rules={{ required: 'Estado CRV é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.estadoCrv })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="estadoCrv" className="font-bold block mb-2 required">{t('uf') + ' ' + t('crv')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => {field.onChange(e.value)}} options={estados} optionLabel="nome" optionValue="sigla" placeholder="Selecione UF CRV" 
                                  filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                  </div>
            </div>
            <div className="grid">
              <div className="col">
                <Controller
                      name="viaCrv"
                      control={control}
                      rules={{ required: 'Via CRV é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.viaCrv })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="viaCrv" className="font-bold block mb-2 required">{t('viaCrv')} </label>
                                  <InputNumber style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} size={4} maxLength={4} max={9999}  useGrouping={false}  />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
              <div className="col">
                <Controller
                      name="codigoCrv"
                      control={control}
                      rules={{ required: 'Código CRV é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.codigoCrv })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="codigoCrv" className="font-bold block mb-2 required">{t('codigoSegurancaCrv')} </label>
                                  <InputNumber style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} size={4} maxLength={4} max={9999}  useGrouping={false}  />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
            </div>
            <div className="grid">
              <div className="col">
                <Controller
                      name="anoModelo"
                      control={control}
                      rules={{ required: 'Ano Modelo é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.anoModelo })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="anoModelo" className="font-bold block mb-2 required">{t('anoModelo')} </label>
                                  <InputNumber min={1900} style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onValueChange={(e) => field.onChange(e.value)} size={4} maxLength={4} max={9999}  useGrouping={false}  />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
              <div className="col">
                <Controller
                      name="anoFabricacao"
                      control={control}
                      rules={{ required: 'Ano Fabricação é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.anoFabricacao })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="anoFabricacao" className="font-bold block mb-2 required">{t('anoFabricacao')} </label>
                                  <InputNumber min={1900} style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onValueChange={(e) => field.onChange(e.value)} size={4} maxLength={4} max={9999}  useGrouping={false}  />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
            </div>              
            <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
              <Button label="Voltar" style={{marginRight:"1rem", backgroundColor:'#183462'}} severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
              <Button icon="pi pi-arrow-right" style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Avançar" type="submit"   />
                  
            </div> 
          </form>
        </StepperPanel>
        <StepperPanel header="Proprietário Atual">
          <form onSubmit={handleSubmit(onSubmit3)} className="flex flex-column gap-2">
            <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="tipoPessoaProprietario"
                          control={control}
                          rules={{ required: 'Tipo Pessoa é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.tipoPessoaProprietario })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="tipoPessoaProprietario" className="font-bold block mb-2 required">{t('tipoPessoa')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) =>{setTipoPessoaProprietario(e.value); field.onChange(e.value)}} options={tiposPessoa} optionLabel="label" placeholder="Selecione Tipo Pessoa" 
                                  filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col p-fluid flex flex-wrap">
                    <Controller
                          name="documentoProprietario"
                          control={control}
                          rules={{ required: 'Documento é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.documentoProprietario })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="documentoProprietario" className="font-bold block mb-2 required">{t('documento')} </label>
                                    {tipoPessoaProprietario && tipoPessoaProprietario === 'FISICA' ? (<InputMask id={field.name} onBlur={(e) => validDocument(e.target.value)}  value={field.value} className={classNames({ 'p-invalid': fieldState.error })} mask="999.999.999-99" onChange={(e) => field.onChange(e.target.value)}></InputMask>) : (<InputMask id={field.name} onBlur={(e) => validDocument(e.target.value)}  value={field.value} className={classNames({ 'p-invalid': fieldState.error })} mask="99.999.999/9999-99" onChange={(e) => field.onChange(e.target.value)}></InputMask>)}
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                  </div>
              </div>
            
            <div className="grid">
              <div className="col">
                    <Controller
                          name="nomeProprietario"
                          control={control}
                          rules={{ required: 'Nome é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.nomeProprietario })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="nomeProprietario" className="font-bold block mb-2 required">{t('nome')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                    
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="telefoneProprietario"
                          control={control}
                          rules={{ required: 'Telefone é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.telefoneProprietario })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="telefoneProprietario" className="font-bold block mb-2 required">{t('fone')} </label>
                                    <InputMask style={{width:'100%'}} mask="(99)99999999?9" id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                    
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="emailProprietario"
                          control={control}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.emailProprietario })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="emailProprietario" className="font-bold block mb-2">{t('email')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                    
                  
                  </div>
                  </div>
                 
                  <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    <Controller
                      name="cepProprietario"
                      control={control}
                      rules={{ required: 'CEP é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.cepProprietario })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="cepProprietario" className="font-bold block mb-2 required">{t('cep')} </label>
                                  <InputText maxLength={8} max={8} style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => {field.onChange(e.target.value); buscarCep(e.target.value, false);}} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                  
                    
                
              </div>
              <div className="col">
                <Controller
                      name="logradouroProprietario"
                      control={control}
                      rules={{ required: 'Logradouro é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.logradouroProprietario })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="logradouroProprietario" className="font-bold block mb-2 required">{t('logradouro')} </label>
                                <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                
              </div>
              <div className="col">
                <Controller
                      name="numeroProprietario"
                      control={control}
                      rules={{ required: 'Número é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.numeroProprietario })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="numeroProprietario" className="font-bold block mb-2 required">{t('numero')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                
              </div>
              <div className="col">
                <Controller
                      name="bairroProprietario"
                      control={control}
                      rules={{ required: 'Bairro é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.bairroProprietario })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="bairroProprietario" className="font-bold block mb-2 required">{t('bairro')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <Controller
                      name="complementoProprietario"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.complementoProprietario })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="complementoProprietario" className="font-bold block mb-2">{t('complemento')} </label>
                                  <InputText maxLength={8} max={8} style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => {field.onChange(e.target.value)}} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                  
                    
                
              </div>
              <div className="col">
                <Controller
                      name="cidadeProprietario"
                      control={control}
                      rules={{ required: 'Cidade é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.cidadeProprietario })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="cidadeProprietario" className="font-bold block mb-2 required">{t('cidade')} </label>
                                <InputText disabled style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                
              </div>
              <div className="col">
                <Controller
                      name="ufProprietario"
                      control={control}
                      rules={{ required: 'UF é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.ufProprietario })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="ufProprietario" className="font-bold block mb-2 required">{t('uf')} </label>
                                  <InputText disabled style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                
              </div>
            </div>
            <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
              <Button label="Voltar" style={{marginRight:"1rem", backgroundColor:'#183462'}} severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
              <Button icon="pi pi-arrow-right" style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Avançar" type="submit"   />
                  
            </div> 
          </form>
        </StepperPanel>
        <StepperPanel header="Comprador">
        <form onSubmit={handleSubmit(onSubmit4)} className="flex flex-column gap-2">
            <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="tipoPessoaComprador"
                          control={control}
                          rules={{ required: 'Tipo Pessoa é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.tipoPessoaComprador })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="tipoPessoaComprador" className="font-bold block mb-2 required">{t('tipoPessoa')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) =>{setTipoPessoaComprador(e.value); field.onChange(e.value)}} options={tiposPessoa} optionLabel="label" placeholder="Selecione Tipo Pessoa" 
                                  filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col p-fluid flex flex-wrap">
                    <Controller
                          name="documentoComprador"
                          control={control}
                          rules={{ required: 'Documento é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.documentoComprador })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="documentoComprador" className="font-bold block mb-2 required">{t('documento')} </label>
                                    {tipoPessoaComprador && tipoPessoaComprador === 'FISICA' ? (<InputMask onBlur={(e) => validDocument(e.target.value)} id={field.name}  value={field.value} className={classNames({ 'p-invalid': fieldState.error })} mask="999.999.999-99" onChange={(e) => field.onChange(e.target.value)}></InputMask>) : (<InputMask id={field.name} onBlur={(e) => validDocument(e.target.value)}  value={field.value} className={classNames({ 'p-invalid': fieldState.error })} mask="99.999.999/9999-99" onChange={(e) => field.onChange(e.target.value)}></InputMask>)}
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                  </div>
              </div>
            <div className="grid">
              <div className="col">
                    <Controller
                          name="nomeComprador"
                          control={control}
                          rules={{ required: 'Nome é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.nomeComprador })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="nomeComprador" className="font-bold block mb-2 required">{t('nome')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                    
                  
                  </div>
                  <div className="col p-fluid flex flex-wrap">
                    <Controller
                          name="telefoneComprador"
                          control={control}
                          rules={{ required: 'Telefone é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.telefoneComprador })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="telefoneComprador" className="font-bold block mb-2 required">{t('fone')} </label>
                                    <InputMask style={{width:'100%'}} mask="(99)99999999?9" id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                     </div>
                  <div className="col">
                    <Controller
                          name="emailComprador"
                          control={control}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.emailComprador })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="emailComprador" className="font-bold block mb-2">{t('email')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                    
                  
                  </div>
            </div>
            {tipoPessoaComprador && tipoPessoaComprador === 'FISICA' && (
            <div className="grid">
              <div className="col">
                  
                    <Controller
                          name="nomeMaeComprador"
                          control={control}
                          rules={{ required: 'Nome Mãe é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.nomeComprador })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="nomeComprador" className="font-bold block mb-2 required">{t('nomeMae')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                      
                  
                  </div>
                  <div className="col">
                    
                    <Controller
                          name="sexoComprador"
                          control={control}
                          rules={{ required: 'Sexo é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.estado })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="ufIdentidadeComprador" className="font-bold block mb-2 required">{t('sexo')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => {field.onChange(e.value)}} options={sexos} optionLabel="name" optionValue="code" placeholder="Selecione Sexo" 
                                  filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                  </div>
                  <div className="col">
                    
                    <Controller
                      name="dataNascimentoComprador"
                      control={control}
                      rules={{ required: 'Data Nascimento é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.dataNascimentoComprador })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="nascimento" className="font-bold block mb-2 required">{t('dt_nascimento')} </label>
                                  <Calendar id='data' value={field.value}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                />
                    
                  
                  </div>
            </div>
             )}
            {tipoPessoaComprador && tipoPessoaComprador === 'FISICA' && (
            <div className="grid">
                    <div className="col">
                      <Controller
                          name="numeroIdentidadeComprador"
                          control={control}
                          rules={{ required: 'Identidade é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.numeroIdentidadeComprador })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="numeroIdentidadeComprador" className="font-bold block mb-2 required">{t('identidade')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                        />
                  
                    </div>
                    <div className="col">
                      <Controller
                          name="orgaoIdentidadeComprador"
                          control={control}
                          rules={{ required: 'Órgão Identidade é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.orgaoIdentidadeComprador })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="orgaoIdentidadeComprador" className="font-bold block mb-2 required">{t('orgaoIdentidade')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                        />
                  
                    </div>
                    <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="ufIdentidadeComprador"
                          control={control}
                          rules={{ required: 'Estado é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.estado })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="ufIdentidadeComprador" className="font-bold block mb-2 required">{t('ufIdentidade')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => {getMunicipio({uf:e.value}).then((data) => setMunicipios(data)); setEstadoFilter(e.value); field.onChange(e.value)}} options={estados} optionLabel="nome" optionValue="sigla" placeholder="Selecione Estado" 
                                  filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                     </div>
                  
                  </div>
            )}
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <Controller
                      name="cepComprador"
                      control={control}
                      rules={{ required: 'CEP é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.cepComprador })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="cepComprador" className="font-bold block mb-2 required">{t('cep')} </label>
                                  <InputText maxLength={8} max={8} style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => {field.onChange(e.target.value); buscarCep(e.target.value, true);}} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                  
                    
                
              </div>
              <div className="col">
                <Controller
                      name="logradouroComprador"
                      control={control}
                      rules={{ required: 'Logradouro é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.logradouroComprador })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="logradouroComprador" className="font-bold block mb-2 required">{t('logradouro')} </label>
                                <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                
              </div>
              <div className="col">
                <Controller
                      name="numeroComprador"
                      control={control}
                      rules={{ required: 'Número é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.numeroComprador })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="numeroComprador" className="font-bold block mb-2 required">{t('numero')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                
              </div>
              <div className="col">
                <Controller
                      name="bairroComprador"
                      control={control}
                      rules={{ required: 'Bairro é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.bairroComprador })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="bairroComprador" className="font-bold block mb-2 required">{t('bairro')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <Controller
                      name="complementoComprador"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.complementoComprador })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="complementoComprador" className="font-bold block mb-2">{t('complemento')} </label>
                                  <InputText maxLength={8} max={8} style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => {field.onChange(e.target.value)}} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                  
                    
                
              </div>
              <div className="col">
                <Controller
                      name="cidadeComprador"
                      control={control}
                      rules={{ required: 'Cidade é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.cidadeComprador })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="cidadeComprador" className="font-bold block mb-2 required">{t('cidade')} </label>
                                <InputText disabled style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                
              </div>
              <div className="col">
                <Controller
                      name="ufComprador"
                      control={control}
                      rules={{ required: 'UF é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.ufComprador })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="ufComprador" className="font-bold block mb-2 required">{t('uf')} </label>
                                  <InputText disabled style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                
              </div>
              
            </div>
            <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
              <Button label="Voltar" style={{marginRight:"1rem", backgroundColor:'#183462'}} severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
              <Button icon="pi pi-arrow-right" style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Avançar" type="submit"   />
                  
            </div> 
          </form>
        </StepperPanel>
        <StepperPanel header="Finalização">
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <Divider align="left">
                <span className="p-tag" style={{backgroundColor : '#183462', fontSize:'1.1rem'}}>Dados Veículo</span>
              </Divider>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <Divider align="left">
                <span className="p-tag" style={{backgroundColor : '#183462', fontSize:'1.1rem'}}>Proprietário Atual</span>
              </Divider>
            </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('placa')}</label>
                    <Chip style={{width:"100%"}} label={venda.placa} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('nomeProprietario')}</label>
                    <Chip style={{width:"100%"}} label={venda.nomeProprietario} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('renavam')}</label>
                    <Chip style={{width:"100%"}} label={venda.renavam} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('documentoProprietario')}</label>
                    <Chip style={{width:"100%"}} label={venda.documentoProprietario} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('crv')}</label>
                    <Chip style={{width:"100%"}} label={venda.crv} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('email')}</label>
                    <Chip style={{width:"100%"}} label={venda.emailProprietario ? venda.emailProprietario : 'Não Informado'} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('ufEmissaoCrv')}</label>
                    <Chip style={{width:"100%"}} label={venda.estadoCrv} />
                </div>
              </div>
              <div className="col">
                <Divider align="left">
                  <span className="p-tag" style={{backgroundColor : '#183462', fontSize:'1.1rem'}}>Comprador</span>
                </Divider>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('dataEmissaoCrv')}</label>
                    <Chip style={{width:"100%"}} label={venda.dataEmissaoCrv ? formatDate(venda.dataEmissaoCrv) : ''} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('nomeComprador')}</label>
                    <Chip style={{width:"100%"}} label={venda.nomeComprador} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('viaCrv')}</label>
                    <Chip style={{width:"100%"}} label={venda.viaCrv} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('documentoComprador')}</label>
                    <Chip style={{width:"100%"}} label={venda.documentoProprietario} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('codigoSegurancaCrv')}</label>
                    <Chip style={{width:"100%"}} label={venda.codigoCrv} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('email')}</label>
                    <Chip style={{width:"100%"}} label={venda.emailComprador ? venda.emailComprador : 'Não Informado'} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('anoModelo')}</label>
                    <Chip style={{width:"100%"}} label={venda.anoModelo} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('endereco')}</label>
                    <Chip style={{width:"100%"}} label={venda.logradouroComprador + ', ' + venda.numeroComprador + ' - ' + venda.bairroComprador + ' - ' + venda.cidadeComprador + '/' + venda.ufComprador + ' - ' + venda.cepComprador} />
                </div>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('anoFabricacao')}</label>
                    <Chip style={{width:"100%"}} label={venda.anoFabricacao} />
                </div>
              </div>
              <div className="col">
              
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <Divider align="left">
                    <span className="p-tag" style={{backgroundColor : '#183462', fontSize:'1.1rem'}}>Venda</span>
                </Divider>
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('localData')}</label>
                    <Chip style={{width:"100%"}} label={(venda.dataVenda ? formatDate(venda.dataVenda) : '') + ' ' + venda.municipioNome + '/' + estadoFilter} />
                </div>
              </div>
            </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div className="flex align-items-center">
                    <Checkbox inputId="conferencia" name="conferencia" onChange={e => {setConferencia(e.checked)}} checked={conferencia? conferencia : false}  />
                    <b><label htmlFor="conferencia" className="ml-2">{t('conferenciaComunicadoVenda')}</label></b>
                  </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div className="flex align-items-center">
                    <Checkbox inputId="conferenciaData" name="conferenciaData" onChange={e => {setConferenciaData(e.checked)}} checked={conferenciaData? conferenciaData : false}  />
                    <label htmlFor="conferenciaData" className="ml-2">CONFIRMO QUE A DATA DE VENDA {venda.dataVenda ? formatDate(venda.dataVenda) : ''} ESTÁ CORRETA (PODENDO SER ANTERIOR A DATA DE AUTENTICAÇÃO)</label>
                  </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div className="flex align-items-center">
                    <Checkbox inputId="conferenciaDocumento" name="conferenciaDocumento" onChange={e => {setConferenciaDocumento(e.checked)}} checked={conferenciaDocumento? conferenciaDocumento : false}  />
                    <label htmlFor="conferenciaDocumento" className="ml-2">{t('conferenciaDocumentoComunicadoVenda')}</label>
                  </div>
            </div>
          </div>
          <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
              <Button label="Voltar" style={{marginRight:"1rem", backgroundColor:'#183462'}} severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
              <Button disabled={!conferencia || !conferenciaData || !conferenciaDocumento} icon="pi pi-save" style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar" onClick={()=>{save(false)}}   />   
              <Button disabled={!conferencia || !conferenciaData || !conferenciaDocumento} icon="pi pi-cloud-upload" style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar e Enviar"  onClick={()=>{save(true)}}  />   
            </div> 
        </StepperPanel>
      </Stepper>
      <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
    
        <Button icon="pi pi-times" onClick={onClose} rounded outlined severity="danger" aria-label="Cancel" />
      </div>

    </>
  )
}


