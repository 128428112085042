import { ReactNode } from "react";
import styles from "./styles.module.css";

interface FieldProps {
  label: string;
  value: ReactNode;
}

export const TextField = ({ label, value }: FieldProps) => {
  return (
    <div className={styles.dadosWrapper}>
      <p className={styles.text}>{label}: </p>&nbsp;
      <p className={`${styles.text} ${styles.value}`}>{value}</p>
    </div>
  );
};
