import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";
import FaturamentoList from './FaturamentoList';
import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import FaturamentoCadastrar from './FaturamentoCadastrar';
import { Messages } from 'primereact/messages';
import postFaturamento from "../../service/postFaturamento";

export default function FaturamentoPage() {
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const [faturamento, setFaturamento] = useState<any | null>(null);
  const labelButton = t('cadastrar')
  const saveFaturamento = (faturamento:any) => {
    
    postFaturamento(faturamento).then(data => {
      if(data.includes('Erro')){
        if (msgs.current) {
          msgs.current?.clear();
          msgs.current?.show([
              { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
              
          ]);
        }
      }
      else{
        setFaturamento(null);
        if (msgs.current) {
          msgs.current?.clear();
          msgs.current?.show([
              { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
              
          ]);
        }
      }
    })
    
    
    
  }
  const startContent = (
    <React.Fragment>
        <Button label={labelButton} icon="pi pi-plus" style={{backgroundColor:'#183462'}} onClick={() => {setFaturamento({})}}/>
    </React.Fragment>
);

  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-wallet" style={{ fontSize: '2.0rem' }}></i> {t('faturamentos')}</h1>
            </div>     
          </div>
      </div>
      <div className="card">
        <Messages ref={msgs} />
        {!faturamento && (
          <>
            <Toolbar start={startContent} />
            <br></br>
            <br></br>
            <FaturamentoList></FaturamentoList>
          </>
        )}
        {faturamento && (
          <>
           <FaturamentoCadastrar faturamento={faturamento} onSave={saveFaturamento} onClose={() => {setFaturamento(null)}}></FaturamentoCadastrar>
          </>
        )}
      </div>
                
    </Container>
  )
}
