import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import styles from "./styles.module.css";

import { Column } from "primereact/column";
import { IAutomotivaFipe } from "../../../../interface";

interface FieldProps {
  label: string;
  value: string;
}

const TextField = ({ label, value }: FieldProps) => {
  return (
    <div className={styles.dadosWrapper}>
      <p className={styles.text}>{label}: </p>
      <p className={`${styles.text} ${styles.value}`}>{`${value || "-"}`}</p>
    </div>
  );
};

export const AutomotivaFipePage = (data: IAutomotivaFipe) => {
  const getValue = (value?: string): string => value || "Não informado";
  const isNotError = data?.mensagem === "Sem erros";

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <p className={styles.title}>Detalhes do veículo</p>
        </div>

        <div className={styles.listaWrapper}>
          {isNotError && (
            <>
              <TextField
                label="Código de Situação"
                value={getValue(data?.veiculo.codigoSituacao)}
              />
              <TextField
                label="Situação"
                value={getValue(data?.veiculo.situacao)}
              />
              <TextField label="Marca" value={getValue(data?.veiculo.marca)} />
              <TextField
                label="Modelo"
                value={getValue(data?.veiculo.modelo)}
              />
              <TextField label="Cor" value={getValue(data?.veiculo.cor)} />
              <TextField
                label="Ano de Fabricação"
                value={getValue(data?.veiculo.ano)}
              />
              <TextField
                label="Ano do Modelo"
                value={getValue(data?.veiculo.anoModelo)}
              />
              <TextField label="Placa" value={getValue(data?.veiculo.placa)} />
              <TextField label="UF" value={getValue(data?.veiculo.uf)} />
              <TextField
                label="Município"
                value={getValue(data?.veiculo.municipio)}
              />
              <TextField
                label="Chassi"
                value={getValue(data?.veiculo.chassi)}
              />
              <TextField label="Data" value={getValue(data?.veiculo.data)} />
            </>
          )}

          {!isNotError && <p>{data?.mensagem}</p>}
        </div>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.header}>
          <p className={styles.title}>Fipe</p>
        </div>

        {isNotError && (
          <Panel>
            <DataTable value={data?.fipes}>
              <Column field="Valor" header="Valor" />
              <Column field="Marca" header="Marca" />
              <Column field="Modelo" header="Modelo" />
              <Column field="AnoModelo" header="Ano do Modelo" />
              <Column field="Combustivel" header="Combustível" />
              <Column field="CodigoFipe" header="Código Fipe" />
              <Column field="MesReferencia" header="Mês de Referência" />
              <Column field="DataConsulta" header="Data da Consulta" />
            </DataTable>
          </Panel>
        )}

        {!isNotError && <p>{data?.mensagem}</p>}
      </div>
    </>
  );
};
