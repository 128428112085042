import { IAutomotivaVeiculoProprietario } from "../../../../interface/AutomotivaVeiculoProprietario";
import { formatDate } from "../../../../utils/format_date";
import styles from "./styles.module.css";

interface FieldProps {
  label: string;
  value: string;
}

const TextField = ({ label, value }: FieldProps) => {
  return (
    <div className={styles.dadosWrapper}>
      <p className={styles.text}>{label}: </p>&nbsp;
      <p className={`${styles.text} ${styles.value}`}>{`${value || "-"}`}</p>
    </div>
  );
};

export const AutomotivaVeiculoProprietario = (
  response: IAutomotivaVeiculoProprietario
) => {
  const { data, ERRO } = response;

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <p className={styles.title}>Detalhes do veículo</p>
        </div>
        <div>
          <div className={styles.listaWrapper}>
            <TextField label="Proprietário" value={data?.nomeProprietario} />
            <TextField
              label="Tipo de Documento"
              value={data?.tipoDocProprietario}
            />
            <TextField
              label="Número Identificação Proprietário"
              value={data?.numIdentProprietario}
            />
            <TextField
              label="Marca/Modelo"
              value={data?.marcaModelo || "Não informado"}
            />
            <TextField label="Cor" value={data?.nomeCor || "Não informado"} />
            <TextField
              label="Ano de Fabricação"
              value={data?.anoFabricacao || "Não informado"}
            />
            <TextField
              label="Ano do Modelo"
              value={data?.anoModelo || "Não informado"}
            />
            <TextField label="Chassi" value={data?.chassi || "Não informado"} />
            <TextField
              label="RENAVAM"
              value={data?.renavam || "Não informado"}
            />
            <TextField label="Placa" value={data?.placa || "Não informado"} />
            <TextField
              label="UF de Jurisdição"
              value={data?.ufJurisdicao || "Não informado"}
            />
            <TextField
              label="Município de Emplacamento"
              value={data?.nomeMunicipioEmplacamento || "Não informado"}
            />
            <TextField
              label="Data da Solicitação"
              value={formatDate(data?.requestCreated) || "Não informado"}
            />
            <TextField
              label="Código da Solicitação"
              value={data?.requestId.toString() || "Não informado"}
            />
          </div>
        </div>
      </div>

      {ERRO && <p>{ERRO}</p>}
    </>
  );
};
