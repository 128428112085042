import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import findServico from '../../service/findServico';
import { Button } from 'primereact/button';
import { SortOrder } from 'primereact/api';
import { useGlobalState } from '../../components/globalState/GlobalStateProvider';




export default function ServicoList({onEdit}:{onEdit:(user:any)=>void}) {
  const { t } = useTranslation();
  const [servicos, setServicos] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);
  const {state} = useGlobalState();
  
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    idFranquia : state.user.franquiaSelected?.id,
    sortField: 'descricao',
    sortOrder: SortOrder.DESC
  });

  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: (event.first / event.rows) + 1,
      idFranquia : state.user.franquiaSelected?.id,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };
  const onSort = (event:any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      idFranquia : state.user.franquiaSelected?.id,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  const loadLazyData = () => {
    findServico(lazyState).then(data => {setTotalRecords(data.total);setServicos(data.servicos)}).catch(() => {setTotalRecords(0);setServicos(undefined)});
  }

  const editBodyTemplate = (servico:any) => {
    return <Button icon="pi pi-pencil" onClick={() => onEdit(servico)} style={{background:'transparent', color:'gray', border: 'none'}} />;
  };
  
  useEffect(() => {
    loadLazyData();
  },[lazyState])

  return (
    <div>
      <div>
        <DataTable value={servicos} first={lazyState.first} dataKey="id" paginator={true}  lazy={true}
                        emptyMessage="Sem registros"
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows} totalRecords={totalRecords} onPage={onPage}
                        tableStyle={{ minWidth: '75rem' }}>
           <Column field="tipoBilhete" sortable header={t('tipoBilhete')}  />
           <Column field="descricao" sortable header={t('descricao')}  />
           <Column body={editBodyTemplate} />
        </DataTable> 
      </div>
    </div>
  )
}


