import { useTranslation } from "react-i18next";
import { Panel } from "primereact/panel";
import { Chip } from "primereact/chip";
import OutPutCard from "../../../components/outputCard";
import { Divider } from "primereact/divider";

export default function AutomotivaGravame({
  automotivaGravame,
}: {
  automotivaGravame: any;
}) {
  const { t } = useTranslation();
  const automotivaAgregado = automotivaGravame.AGREGADOSXML;
  return (
    <Panel header={t("Automotiva Agregado")} style={{ width: "98%" }}>
      <Divider align="left">
        <div className="flex-score justify-content-between justify-content-center">
          <span
            className="p-tag"
            style={{
              position: "relative",
              backgroundColor: "#183462",
              fontSize: "1.1rem",
              height: "2rem",
              marginTop: "0.5rem",
            }}
          >
            Resumo do Veículo
          </span>
        </div>
      </Divider>
      <br></br>
      <div className="grid">
        <div className="col p-fluid flex flex-wrap">
          <div>
            <label className="label-dados">{t("placa")}</label>
            <Chip
              style={{ width: "100%" }}
              label={automotivaGravame.GRAVAMEXML.PLACA}
            />
          </div>
        </div>
        <div className="col">
          <div>
            <label className="label-dados">{t("marcaModelo")}</label>
            <Chip
              style={{ width: "100%" }}
              label={automotivaAgregado.AGREGADOS_MARCAMODELO}
            />
          </div>
        </div>
        <div className="col">
          <div>
            <label className="label-dados">{t("chassi")}</label>
            <Chip
              style={{ width: "100%" }}
              label={automotivaGravame.RETORNO.CHASSI_SNG}
            />
          </div>
        </div>
        <div className="col">
          <div>
            <label className="label-dados">{t("motor")}</label>
            <Chip
              style={{ width: "100%" }}
              label={automotivaAgregado.AGREGADOS_MOTOR}
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col p-fluid flex flex-wrap">
          <div>
            <label className="label-dados">{t("renavam")}</label>
            <Chip
              style={{ width: "100%" }}
              label={automotivaAgregado.AGREGADOS_RENAVAM}
            />
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <label className="label-dados">{t("cor")}</label>
            <Chip
              style={{ width: "100%" }}
              label={automotivaAgregado.AGREGADOS_COR}
            />
          </div>
        </div>
        <div className="col">
          <div>
            <label className="label-dados">{t("combustivel")}</label>
            <Chip
              style={{ width: "100%" }}
              label={automotivaAgregado.AGREGADOS_COMBUSTIVEL}
            />
          </div>
        </div>
        <div className="col">
          <div>
            <label className="label-dados">{t("anoFabricaoModelo")}</label>
            <Chip
              style={{ width: "100%" }}
              label={
                automotivaAgregado.AGREGADOS_ANOFABRICACAO +
                "/" +
                automotivaAgregado.AGREGADOS_ANOMODELO
              }
            />
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <Divider align="left">
        <span
          className="p-tag"
          style={{ backgroundColor: "#183462", fontSize: "1.1rem" }}
        >
          Agregados
        </span>
      </Divider>
      <br></br>
      <div className="grid">
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Chassi"
              value={automotivaGravame.RETORNO.CHASSI_SNG}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Cilindradas"
              value={automotivaAgregado.AGREGADOS_CAPACIDADE_CILINDRADA}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Espécie"
              value={automotivaAgregado.AGREGADOS_ESPECIE}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Combustível"
              value={automotivaAgregado.AGREGADOS_COMBUSTIVEL}
            ></OutPutCard>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Potência"
              value={automotivaAgregado.AGREGADOS_POTENCIA}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Modelo"
              value={automotivaAgregado.AGREGADOS_MARCAMODELO}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Marca"
              value={automotivaAgregado.FABRICANTE}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Placa"
              value={automotivaGravame.GRAVAMEXML.PLACA}
            ></OutPutCard>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Ano Fabricação"
              value={automotivaAgregado.AGREGADOS_ANOFABRICACAO}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Ano Modelo"
              value={automotivaAgregado.AGREGADOS_ANOMODELO}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Capacidade Passageiros"
              value={automotivaAgregado.AGREGADOS_QUANTIDADE_PASSAGEIROS}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Tipo"
              value={automotivaAgregado.AGREGADOS_TIPO}
            ></OutPutCard>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Município"
              value={automotivaAgregado.MUNICIPIO}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="UF"
              value={automotivaGravame.GRAVAMEXML.UFPLACA}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Procedência"
              value={automotivaAgregado.AGREGADOS_PROCEDENCIA}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Motor"
              value={automotivaAgregado.AGREGADOS_MOTOR}
            ></OutPutCard>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Cor"
              value={automotivaAgregado.AGREGADOS_COR}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Renavan"
              value={automotivaAgregado.AGREGADOS_RENAVAM}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Situação chassi"
              value={automotivaAgregado.AGREGADOS_SITUACAO_CHASSI}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="CMT"
              value={automotivaAgregado.AGREGADOS_CMT}
            ></OutPutCard>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Data Atualização"
              value={automotivaAgregado.AGREGADOS_DATA_ATUALIZAO}
            ></OutPutCard>
          </div>
        </div>

        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Capacidade Carga"
              value={automotivaAgregado.AGREGADOS_CAPACIDADE_CARGA}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="PBT"
              value={automotivaAgregado.AGREGADOS_PBT}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Tipo Carroceria"
              value={automotivaAgregado.AGREGADOS_TIPO_CARROCERIA}
            ></OutPutCard>
          </div>
        </div>
      </div>

      <br></br>
      <br></br>
      <Divider align="left">
        <span
          className="p-tag"
          style={{ backgroundColor: "#183462", fontSize: "1.1rem" }}
        >
          Gravame
        </span>
      </Divider>
      <br></br>
      <div className="grid">
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Código Financeira"
              value={automotivaGravame.GRAVAMEXML.CODIGOFINANCEIRA}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Vigência"
              value={automotivaGravame.GRAVAMEXML.DATAGRAVAMEVIGENCIA}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="UF Gravame"
              value={automotivaGravame.GRAVAMEXML.UFGRAVAME}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Data Gravame"
              value={automotivaGravame.GRAVAMEXML.DATAGRAVAME}
            ></OutPutCard>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Status"
              value={automotivaGravame.GRAVAMEXML.DESCRICAOSTATUS}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Financeira"
              value={automotivaGravame.GRAVAMEXML.FINANCEIRANOME}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Documento Financeira"
              value={automotivaGravame.GRAVAMEXML.DOCUMENTOFINANCEIRA}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Contrato"
              value={automotivaGravame.GRAVAMEXML.NUMEROCONTRATO}
            ></OutPutCard>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Documento Proprietário"
              value={automotivaGravame.GRAVAMEXML.DOCUMENTOPROPRIETARIOATUAL}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Status Veículo"
              value={automotivaGravame.GRAVAMEXML.STATUSDOVEICULO}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap">
          <div>
            <OutPutCard
              label="Nome Financiado"
              value={automotivaGravame.GRAVAMEXML.NOMEFINANCIADO}
            ></OutPutCard>
          </div>
        </div>
        <div className="col p-fluid flex flex-wrap"></div>
      </div>
    </Panel>
  );
}
