import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { Controller, useForm} from 'react-hook-form';
import { Button } from "primereact/button";
import getTipoBilhete from "../../service/getTipoBilhete";
import { SetStateAction, useEffect, useState } from "react";
import { Tree, TreeCheckboxSelectionKeys, TreeMultipleSelectionKeys } from "primereact/tree";
import getPermissao from "../../service/getPermissao";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";


export default function FaturamentoCadastrar({faturamento, onClose, onSave}:{faturamento:any, onClose: () => void, onSave: (faturamneto:any) => void}) {
  const { t } = useTranslation();
  const defaultValues = {
    dataReferencia: null,
    dataInicio: null,
    dataFim: null,
    dataVencimento: null
  };
 
  
  const {
      control,
      formState: { errors},
      handleSubmit
  } = useForm({ defaultValues });

  const onSubmit = (data:any) => {
      data.dataReferencia && save(data);
  };

 
  const save = (data:any) => {
    const fat = {
      dataReferencia:data.dataReferencia,
      dataInicio:data.dataInicio,
      dataFim:data.dataFim,
      dataVencimento:data.dataVencimento
    }
    window.scrollTo(0, 0);
    onSave(fat);
  }
  
  const getFormErrorMessage = (name:string) => {
    type ObjectKey = keyof typeof errors;
    const error = errors[Object.keys(errors).find(k => k === name) as ObjectKey]
    return error ? <small className="p-error">{error.message}</small> : <small className="p-error">&nbsp;</small>;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
        <div>
          <div className="grid">
              <div className="col ">
                
                  <Controller
                      name="dataReferencia"
                      control={control}
                      rules={{ required: 'Data Referência é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.dataReferencia })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="dataReferencia" className="font-bold block mb-2 required">{t('dataReferencia')} </label>
                                <Calendar id='data' value={field.value}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="mm/yy" locale='pt'/>
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                    
               
              </div>
              <div className="col">
                <Controller
                      name="dataVencimento"
                      control={control}
                      rules={{ required: 'Data Vencimento é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.dataVencimento })}></label>
                              <span className="">
                              <div>
                              <label htmlFor="dataVencimento" className="font-bold block mb-2 required">{t('dataVencimento')} </label>
                              <Calendar id='data' value={field.value}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
          </div>
          <div className="grid">
              <div className="col">
                
                  <Controller
                      name="dataInicio"
                      control={control}
                      rules={{ required: 'Data Início é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.dataInicio })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="dataInicio" className="font-bold block mb-2 required">{t('dataInicio')} </label>
                                <Calendar id='dataInicio' value={field.value}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                    
               
              </div>
              <div className="col">
                <Controller
                      name="dataFim"
                      control={control}
                      rules={{ required: 'Data Fim é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.dataFim })}></label>
                              <span className="">
                              <div>
                              <label htmlFor="dataFim" className="font-bold block mb-2 required">{t('dataFim')} </label>
                              <Calendar id='dataFim' value={field.value}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
          </div>
       
        </div>
        <br></br>
        <br></br>
        <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar" type="submit"   />
            <Button icon="pi pi-times" onClick={onClose} rounded outlined severity="danger" aria-label="Cancel" />
        </div> 
    </form>
    
  )
}

