import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { Controller, useForm} from 'react-hook-form';
import { Button } from "primereact/button";
import getTipoBilhete from "../../service/getTipoBilhete";
import { SetStateAction, useEffect, useState } from "react";
import { Tree, TreeCheckboxSelectionKeys, TreeMultipleSelectionKeys } from "primereact/tree";
import getPermissao from "../../service/getPermissao";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";


export default function ClienteCadastrar({servico, onClose, onSave}:{servico:any, onClose: () => void, onSave: (user:any) => void}) {
  const { t } = useTranslation();
  const [tiposBilhete, setTiposBilhete] = useState<any>([]);
  const defaultValues = {
    descricao: '',
    tipoBilhete: null
  };
  defaultValues.descricao = servico.descricao ? servico.descricao.toString() : ''
  defaultValues.tipoBilhete = servico.tipoBilhete ? servico.tipoBilhete.toString() : null
 
  
  const {
      control,
      formState: { errors},
      handleSubmit
  } = useForm({ defaultValues });

  const onSubmit = (data:any) => {
      data.descricao && save(data);
  };

 
  const save = (data:any) => {
    const servi = {
      id: servico.id? servico.id : undefined,
      descricao:data.descricao,
      tipoBilhete:data.tipoBilhete
    }
    window.scrollTo(0, 0);
    onSave(servi);
  }
  
  const getFormErrorMessage = (name:string) => {
    type ObjectKey = keyof typeof errors;
    const error = errors[Object.keys(errors).find(k => k === name) as ObjectKey]
    return error ? <small className="p-error">{error.message}</small> : <small className="p-error">&nbsp;</small>;
  };

  useEffect(() => {
    getTipoBilhete().then( (data) => setTiposBilhete(data))
  }, [])



  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
        <div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                
                  <Controller
                      name="descricao"
                      control={control}
                      rules={{ required: 'Descrição é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.descricao })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="descricao" className="font-bold block mb-2 required">{t('descricao')} </label>
                                <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                    
               
              </div>
              <div className="col">
                <Controller
                      name="tipoBilhete"
                      control={control}
                      rules={{ required: 'Documento é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.tipoBilhete })}></label>
                              <span className="">
                              <div>
                              <label htmlFor="tipoBilhete" className="font-bold block mb-2 required">{t('tipoBilhete')} </label>
                                <Dropdown disabled={servico.id} style={{width: '50%'}} value={field.value}  onChange={(e) => field.onChange(e.value)} options={tiposBilhete} optionLabel="label" placeholder="Selecione Tipo Bilhete" 
                                  filter className="w-full md:w-14rem" />  
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
          </div>
          
       
        </div>
        <br></br>
        <br></br>
        <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar" type="submit"   />
            <Button icon="pi pi-times" onClick={onClose} rounded outlined severity="danger" aria-label="Cancel" />
        </div> 
    </form>
    
  )
}

