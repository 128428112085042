import {
  ApiResponseCreditoSimples,
  DadosFormatados,
  CreditoSimplesProps,
} from "./types";
import { Panel } from 'primereact/panel';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Message } from 'primereact/message';
import './index.css';
import { formatSpecificValuesToString } from '../../../../utils/ValidationUtils';

const labelDadosGerais = (key: string): string => {
  const labelDadosGerais: { [key: string]: string } = {
    chequeConsultaOnlineSrs: "Cheque Consulta Online SRS:",
    quadroSocialMaisCompletoPj: "Quadro Social Mais Completo (PJ):",
    telefoneConsultado: "Telefone Consultado:",
    chequeOutrasOcorrenciasSrs: "Cheque Outras Ocorrências (SRS):",
    ocupacao: "Ocupação:",
    informacoesComplementares: "Informações Complementares:",
    registroConsulta: "Registro de Consulta:",
    chequeSemFundoAchei: "Cheque Sem Fundo (Achei):",
    historicoKmVeiculo: "Histórico de KM do Veículo:",
    informacoesAdicionais: "Informações Adicionais:",
    alertaObito: "Alerta de Óbito:",
    perfilFinanceiroPj: "Perfil Financeiro (PJ):",
    contraOrdemDocumentoDiferenteSrs: "Contra Ordem Documento Diferente (SRS):",
    contraOrdemAgenciaDiferente: "Contra Ordem Agência Diferente:",
    participacaoEmpresa: "Participação na Empresa:",
    alertaIdentidade: "Alerta de Identidade:",
    dadosAgenciaBancaria: "Dados da Agência Bancária:",
    limiteCreditoPj: "Limite de Crédito (PJ):",
    historicoAvariasVeiculo: "Histórico de Avarias do Veículo:",
    renavamEstadual: "Renavam Estadual:",
    debitoEstadualVeiculo: "Débito Estadual do Veículo:",
    principaisProdutos: "Principais Produtos:",
    restricaoEstadualVeiculo: "Restrição Estadual do Veículo:",
    participacaoFalencia: "Participação em Falência:",
    rouboFurtoVeiculo: "Roubo ou Furto do Veículo:",
    gastoEstimadoPj: "Gasto Estimado (PJ):",
    baseNacionalVeiculo: "Base Nacional do Veículo:",
    ultimoEnderecoInformado: "Último Endereço Informado:",
    gastoEstimadoPf: "Gasto Estimado (PF):",
    biometriaFacial: "Biometria Facial:",
    spcScore12Meses: "SPC Score (12 meses):",
    confirmacaoRg: "Confirmação do RG:",
    riskscoring6Meses: "Risk Scoring (6 meses):",
    movimentacaoCadastroPositivo: "Movimentação no Cadastro Positivo:",
    socio: "Sócio:",
    decodificadorVeiculo: "Decodificador do Veículo:",
    historicoPagamentoPorFaixa: "Histórico de Pagamento por Faixa:",
    creditoConcedido: "Crédito Concedido:",
    incorporacaoFusaoCisao: "Incorporação, Fusão ou Cisão:",
    rodasPneusVeiculo: "Rodas e Pneus do Veículo:",
    contraOrdemDocumentoDiferente: "Contra Ordem Documento Diferente:",
    referenciaisNegociosPorFaixa: "Referenciais de Negócios por Faixa:",
    contraOrdemSrs: "Contra Ordem (SRS):",
    leilaoVeiculo: "Leilão do Veículo:",
    capitalSocial: "Capital Social:",
    indiceRelacionamentoMercadoPj: "Índice de Relacionamento com o Mercado (PJ):",
    data: "Data:",
    bancoAgenciaContaDocumentoDiferente: "Banco/Agência/Conta Documento Diferente:",
    indiceRelacionamentoMercadoPf: "Índice de Relacionamento com o Mercado (PF):",
    recallVeiculo: "Recall do Veículo:",
    contraOrdemAgenciaContaDiferente: "Contra Ordem Agência/Conta Diferente:",
    parecerTecnicoVeiculo: "Parecer Técnico do Veículo:",
    spcScore3Meses: "SPC Score (3 meses):",
    relacionamentoMaisAntigoComFornecedores: "Relacionamento Mais Antigo com Fornecedores:",
    indicioSinistroVeiculo: "Indício de Sinistro do Veículo:",
    protocolo: "Protocolo:",
    informacaoPoderJudiciario: "Informação do Poder Judiciário:",
    mensagemBaseExterna: "Mensagem da Base Externa:",
    telefoneVinculadoAssinanteConsultado: "Telefone Vinculado ao Assinante Consultado:",
    chequeSemFundoVarejo: "Cheque Sem Fundo (Varejo):",
    baseInoperante: "Base Inoperante:",
    protesto: "Protesto:",
    atividadeEmpresa: "Atividade da Empresa:",
    limiteCreditoSugerido: "Limite de Crédito Sugerido:",
    indiceConsultaRealizadaPorSegmentoCadastroPositivo: "Índice de Consultas Realizadas por Segmento (Cadastro Positivo):",
    indicePontualidadePagamentoCadastroPositivo: "Índice de Pontualidade de Pagamento (Cadastro Positivo):",
    administrador: "Administrador:",
    filial: "Filial:",
    comprometimentoRendaMensalPf: "Comprometimento da Renda Mensal (PF):",
    dpvat: "DPVAT:",
    faturamentoPresumido: "Faturamento Presumido:",
    localizaVeiculo: "Localiza Veículo:",
    gravameVeiculo: "Gravame do Veículo:",
    spcObito: "SPC Óbito:",
    riscoCreditoPj: "Risco de Crédito (PJ):",
    collectionScorePlus: "Collection Score Plus:",
    contraOrdem: "Contra Ordem:",
    operador: "Operador:",
    ultimoTelefoneInformado: "Último Telefone Informado:",
    rendaPresumidaSpc: "Renda Presumida (SPC):",
    restricao: "Restrição:",
    antecessora: "Antecessora:",
    mensagemComplementar: "Mensagem Complementar:",
    telefoneAlternativo: "Telefone Alternativo:",
    indiceComportamentoGastosCadastroPositivo: "Índice de Comportamento de Gastos (Cadastro Positivo):",
    ultimasConsultas: "Últimas Consultas:",
    telefoneVinculadoConsumidor: "Telefone Vinculado ao Consumidor:",
    contumaciaSrs: "Contumácia (SRS):",
    renavamFederal: "Renavam Federal:",
    precificadorVeiculo: "Precificador do Veículo:",
    limiteCreditoSugeridoSar: "Limite de Crédito Sugerido (SAR):",
    gravame: "Gravame:",
    restricaoFinanceira: "Restrição Financeira:",
    historicoPagamentoCadastroPositivo: "Histórico de Pagamento (Cadastro Positivo):",
    riskscoring12Meses: "Risk Scoring (12 meses):",
    consultaRealizadaCheque: "Consulta Realizada (Cheque):",
    chequeSemFundoAcheiCcf: "Cheque Sem Fundo (Achei/CCF):",
    grafiaPj: "Grafia (PJ):",
    acao: "Ação:",
    rouboFurto: "Roubo ou Furto:",
    restricaoNacionalVeiculo: "Restrição Nacional do Veículo:",
    contumacia: "Contumácia:"
  };

  return labelDadosGerais[key] || key;
};

const labelResumo = (key: string): string => {
  const labelResumo: { [key: string]: string } = {
    mesanoUltimaOcorrencia: "Mês e Ano da Última Ocorrência:",
    dataPrimeiraOcorrencia: "Data da Primeira Ocorrência:",
    valorTotal: "Valor Total:",
    mesanoPrimeiraOcorrencia: "Mês e Ano da Primeira Ocorrência:",
    quantidadeTotal: "Quantidade Total:",
    dataUltimaOcorrencia: "Data da Última Ocorrência:",
    valorUltimaOcorrencia: "Valor da Última Ocorrência:"
  };

  return labelResumo[key] || key;
};

const labelConsumidor = (key: string): string => {
  const labelConsumidor: { [key: string]: string } = {
    estadoRg: "Estado do RG",
    telefoneResidencial: "Telefone Residencial",
    endereco: "Endereço",
    telefoneComercial: "Telefone Comercial",
    estadoCivil: "Estado Civil",
    telefoneCelular: "Telefone Celular",
    situacaoCpf: "Situação do CPF",
    dataExpedicaoRg: "Data de Expedição do RG",
    numeroRg: "Número do RG",
    idade: "Idade",
    signo: "Signo",
    cpf: "CPF",
    nomePai: "Nome do Pai",
    nomeSocial: "Nome Social",
    fax: "Fax",
    nacionalidade: "Nacionalidade",
    dataNascimento: "Data de Nascimento",
    pessoaEstrangeira: "Pessoa Estrangeira",
    sexo: "Sexo",
    numeroTituloEleitor: "Número do Título de Eleitor",
    email: "E-mail",
    nomeMae: "Nome da Mãe",
  };

  return labelConsumidor[key] || key;
};

const formatarDadosConsumidor = (dados: any, categoriaNome: string): DadosFormatados => {
  let dadosFormatados: { label: string; value: string }[] = [];

  dadosFormatados = Object.entries(dados).filter(([key]) => labelConsumidor(key) !== key).map(([key, value]) => ({
    label: labelConsumidor(key),
    value: formatSpecificValuesToString(key, value),
  }));

  return {
    categoria: categoriaNome,
    dados: dadosFormatados,
    mensagemAlerta: '',
    array: [],
    quantidadeDiasConsultados: '',
  };
};

const formatarDadosGerais = (dados: any, categoriaNome: string): DadosFormatados => {
  let dadosFormatados: { label: string; value: string }[] = [];

  dadosFormatados = Object.entries(dados).filter(([key]) => labelDadosGerais(key) !== key).map(([key, value]) => ({
    label: labelDadosGerais(key),
    value: formatSpecificValuesToString(key, value),
  }));

  return {
    categoria: categoriaNome,
    dados: dadosFormatados,
    mensagemAlerta: '',
    array: [],
    quantidadeDiasConsultados: '',
  };
};

const formatarDadosDetalhes = (dados: any, categoriaNome: string): DadosFormatados => {
  let dadosFormatados: { label: string; value: string }[] = [];

  dadosFormatados = Object.entries(dados.resumo).filter(([key]) => labelResumo(key) !== key).map(([key, value]) => ({
    label: labelResumo(key),
    value: formatSpecificValuesToString(key, value as string),
  }));

  const resultado: JSX.Element[] = [];
  if (dados.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista) {
    const renderizarDinamico = (rowData: any, field: string) => {
      const value = rowData[field];
      return formatSpecificValuesToString(field, value);
    };

    if (categoriaNome === 'Score Cadastro Positivo') {
      resultado.push(
        <DataTable header="Detalhe Grafia PJ, SPC ou Cheque Lojista" size="normal" stripedRows value={dados.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista}>
          <Column field="scoreMaximoEscala" header="Score Máximo Escala" />
          <Column field="score" header="Score" />
          <Column field="mensagemStatus" header="Status Mensagem" />
          <Column field="mensagem" header="Mensagem" />
          <Column field="probabilidadeInadimplencia" header="Probabilidade Inadimplencia" />
          <Column field="indiceRiscoCreditoScore" header="Indice de Risco Crédito Score" />
        </DataTable>
      );
    } else if (categoriaNome === 'Consulta Realizada') {
      resultado.push(
        <DataTable header="Detalhe Grafia PJ, SPC ou Cheque Lojista" size="normal" stripedRows value={dados.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista}>
          <Column
            field="origemAssociado"
            header="Origem Associado"
            body={(rowData) => renderizarDinamico(rowData, 'origemAssociado')}
          />
          <Column field="nomeAssociado" header="Nome Associado" />
          <Column field="dataConsulta" header="Data Consulta" />
          <Column field="nomeEntidadeOrigem" header="Nome Entidade Origem" />
        </DataTable>
      );
    } else if (categoriaNome == "Alerta Documento") {
      resultado.push(
        <DataTable header="Detalhe Grafia PJ, SPC ou Cheque Lojista" size="normal" stripedRows value={dados.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista}>
          <Column field="dataOcorrencia" header="Data Ocorrencia" />
          <Column field="motivo" header="Motivo" />
          <Column field="observacao" header="Observação" />
          <Column field="tipoDocumentoAlerta" header="Tipo Documento Alerta" body={(rowData) => renderizarDinamico(rowData, 'origemAssociado')} />
          <Column field="dataInclusao" header="Data Inclusão" />
          <Column field="entidadeOrigem" header="Entidade Origem" />
        </DataTable>
      );
    } else if (categoriaNome == "SPC") {
      resultado.push(
        <DataTable header="Detalhe Grafia PJ, SPC ou Cheque Lojista" size="normal" stripedRows value={dados.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista}>
          <Column field="registroInstituicaoFinanceira" header="Registro Instituição Financeira" />
          <Column field="valor" header="Valor" body={(rowData) => renderizarDinamico(rowData, 'valor')} />
          <Column field="contrato" header="Contrato" />
          <Column field="nomeAssociado" header="Nome Associado" />
          <Column field="contaContrato" header="Conta Contrato" />
          <Column field="reservado" header="Reservado" />
          <Column field="registroRelevante" header="Registro Relevante" />
          <Column field="codigoEntidade" header="Código Entidade" />
          <Column field="cidadeAssociado" header="Cidade Associado" body={(rowData) => renderizarDinamico(rowData, 'cidadeAssociado')} />
          <Column field="compradorFiadorAvalista" header="Comprador Fiador Avalista" />
          <Column field="dataVencimento" header="Data Vencimento" />
          <Column field="dataInclusao" header="Data Inclusão" />
          <Column field="telefoneAssociado" header="Telefone Associado" body={(rowData) => renderizarDinamico(rowData, 'telefoneAssociado')} />
          <Column field="nomeEntidade" header="Nome Entidade" />
        </DataTable>
      );
    } else if (categoriaNome == "Pendência Financeira") {
      resultado.push(
        <DataTable header="Detalhe Grafia PJ, SPC ou Cheque Lojista" size="normal" stripedRows value={dados.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista}>
          <Column field="cidade" header="Cidade" body={(rowData) => renderizarDinamico(rowData, 'cidade')} />
          <Column field="dataOcorrencia" header="Data Ocorrência" />
          <Column field="moeda" header="Moeda" body={(rowData) => renderizarDinamico(rowData, 'moeda')} />
          <Column field="filial" header="Filial" />
          <Column field="indicadorSubjudice" header="Indicador Subjudice" />
          <Column field="origem" header="Origem" />
          <Column field="tituloOcorrencia" header="Título Ocorrência" />
          <Column field="subjudice" header="Subjudice" />
          <Column field="contrato" header="Contrato" />
          <Column field="naturezaAnotacao" header="Natureza Anotação" />
          <Column field="avalista" header="Avalista" body={(rowData) => renderizarDinamico(rowData, 'avalista')} />
          <Column field="valorPendencia" header="Valor Pendência" body={(rowData) => renderizarDinamico(rowData, 'valorPendencia')} />
        </DataTable>
      );
    } else {
      const fields = Object.keys(dados.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista[0] || {});
      resultado.push(
        <DataTable header="Detalhe Grafia PJ, SPC ou Cheque Lojista" size="normal" stripedRows value={dados.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista}>
          {fields.map((field) => (
            <Column
              key={field}
              field={field}
              header={labelResumo(field)}
              body={(rowData) => renderizarDinamico(rowData, field)}
            />
          ))}
        </DataTable>
      );
    }
  }

  return {
    categoria: categoriaNome,
    dados: dadosFormatados,
    mensagemAlerta: dados.mensagemAlerta,
    array: resultado,
    quantidadeDiasConsultados: dados.quantidadeDiasConsultados ? 'Quantidade Dias Consultados: ' + dados.quantidadeDiasConsultados : '',
  };
};

const getDadosGerais = (
  data: ApiResponseCreditoSimples
): DadosFormatados[] => {
  const resultado: DadosFormatados[] = [];

  if (data)
    resultado.push(formatarDadosGerais(data, "Dados Gerais"));

  return resultado;
};

const getDadosConsumidor = (
  data: ApiResponseCreditoSimples
): DadosFormatados[] => {
  const resultado: DadosFormatados[] = [];

  if (data.consumidor.consumidorPessoaFisica)
    resultado.push(formatarDadosConsumidor(data.consumidor.consumidorPessoaFisica, "Consumidor Pessoa Fisica "));

  if (data.consumidor.consumidorPessoaJuridica)
    resultado.push(formatarDadosConsumidor(data.consumidor.consumidorPessoaJuridica, "Consumidor Pessoa Juridica "));

  return resultado;
};

const getDadosDetalhes = (
  data: ApiResponseCreditoSimples
): DadosFormatados[] => {
  const resultado: DadosFormatados[] = [];

  if (data.scoreCadastroPositivo)
    resultado.push(formatarDadosDetalhes(data.scoreCadastroPositivo, "Score Cadastro Positivo"));

  if (data.consultaRealizada)
    resultado.push(formatarDadosDetalhes(data.consultaRealizada, "Consulta Realizada"));

  if (data.ccf)
    resultado.push(formatarDadosDetalhes(data.ccf, "CCF"));

  if (data.alertaDocumento)
    resultado.push(formatarDadosDetalhes(data.alertaDocumento, "Alerta Documento"));

  if (data.chequeLojista)
    resultado.push(formatarDadosDetalhes(data.chequeLojista, "Cheque Lojista"));

  if (data.pendenciaFinanceira)
    resultado.push(formatarDadosDetalhes(data.pendenciaFinanceira, "Pendência Financeira"));

  if (data.spc)
    resultado.push(formatarDadosDetalhes(data.spc, "SPC"));

  return resultado;
};

export function CreditoSimples({
  data,
}: CreditoSimplesProps) {
  const detalhes = getDadosDetalhes(data);
  const consumidor = getDadosConsumidor(data);
  const dadosGerais = getDadosGerais(data);

  return (
    <>
      <Panel className="p-panel-header-green" header="Crédito Simples" style={{ width: '98%' }}>
        <><Panel className="p-panel-header-green" header="Consumidor" toggleable>
          {consumidor.map(({ dados, categoria }) => (
            <DataTable header={categoria} showGridlines size="normal" stripedRows value={dados}>
              <Column className="font-bold" field="label" header="Descrição" />
              <Column field="value" header="Valor" />
            </DataTable>
          ))}
        </Panel><br></br></>
        <><Panel className="p-panel-header-green" header="Dados Gerais" toggleable>
          {dadosGerais.map(({ dados }) => (
            <DataTable showGridlines size="normal" stripedRows value={dados}>
              <Column className="font-bold" field="label" header="Descrição" />
              <Column field="value" header="Valor" />
            </DataTable>
          ))}
        </Panel><br></br></>
        {detalhes.map(({ categoria, mensagemAlerta, dados, array, quantidadeDiasConsultados }) => (
          <><Panel className="p-panel-header-green" header={categoria} toggleable>
            {mensagemAlerta && <><Message severity="warn" text={mensagemAlerta} /><br></br></>}
            {quantidadeDiasConsultados && <><Message severity="info" text={quantidadeDiasConsultados} /><br></br></>}
            <DataTable header="Resumo" showGridlines size="normal" stripedRows value={dados}>
              <Column className="font-bold" field="label" header="Descrição" />
              <Column field="value" header="Valor" />
            </DataTable>
            <br></br>
            {array.map((tabela) => (
              <div key={tabela.key}>{tabela}</div>
            ))}
          </Panel><br></br></>
        ))}
      </Panel>
    </>
  );
}
