import { http } from '../../config/axios'

 const findPlano = (params:any): Promise<any> => {
  const queryParams = params
                ? Object.keys(params)
                      .map((k) => encodeURIComponent(params[k]))
                      .join('/')
                : '';
  return http
    .get('api/plano/find/' + queryParams)
    .then((res) => {
      if(res.data && res.data.planos && res.data.planos.length > 0){
        res.data.planos.forEach((p:any) => {
          p.planosServico.forEach((ps:any) => {
            ps.planosServicoValor.forEach((psv:any) => {
              psv.data = new Date(psv.data)
            });
          });
        });
      }
      return res.data as any
    })
    .catch((error) => {
      console.error('Erro findPlano', error)
      throw Error(error.response.data)
    })
}

export default findPlano;
