import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InfoDisplay } from "../../../components/InfoDisplay";
import { IAutomotivaAlertaVeicular } from "../../../interface";

export const AutomotivaAlertaVeicular = (data: IAutomotivaAlertaVeicular) => {
  const getValue = (value?: string): string => value || "Não informado";

  return (
    <div style={{ width: "98%" }}>
      <header
        style={{
          width: "100%",
          backgroundColor: "#20eb1f",
          padding: "8px 16px",
          marginBottom: "1.5rem",
          borderRadius: "8px",
        }}
      >
        <p style={{ margin: "0px", fontWeight: "500" }}>Alerta Veicular</p>
      </header>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "24px",
        }}
      >
        <InfoDisplay label="Placa" value={getValue(data?.ALERTAS?.PLACA)} />
        <InfoDisplay label="Chassi" value={getValue(data?.ALERTAS?.CHASSI)} />
      </div>

      <br />
      <br />

      <DataTable value={data?.ALERTAS?.OCORRENCIAS ?? []}>
        <Column field="DESCRICAO" header="Título" />
        <Column field="MENSAGEM" header="Mensagem" />
        <Column field="SEVERIDADE" header="Severidade" />
      </DataTable>
    </div>
  );
};
