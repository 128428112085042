import { ReactNode } from "react";
import { openPDF } from "../../../../utils/open_pdf_base64";
import styles from "./styles.module.css";

interface FieldProps {
  label: string;
  value: ReactNode;
}

const TextField = ({ label, value }: FieldProps) => {
  return (
    <div className={styles.dadosWrapper}>
      <p className={styles.text}>{label}: </p>&nbsp;
      <p className={`${styles.text} ${styles.value}`}>{value}</p>
    </div>
  );
};

export const AutomotivaCRLVPage = (data: any) => {
  const getValue = (value?: string): string => value || "Não informado";

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>
          CRLV - Certificado de Registro e Licenciamento de Veículo
        </p>
      </div>
      <div className={styles.listaWrapper}>
        <TextField
          label="Ano de fabricação"
          value={getValue(data?.AnoFabricacao)}
        />

        <TextField label="Ano modelo" value={getValue(data?.AnoModelo)} />

        <TextField label="Marca" value={getValue(data?.Marca)} />

        <TextField label="Placa" value={getValue(data?.Placa)} />

        <TextField label="Renavam" value={getValue(data?.Renavam)} />

        <TextField label="Chassi" value={getValue(data?.Chassi)} />

        <TextField label="UF" value={getValue(data?.UF)} />

        <TextField label="Município" value={getValue(data?.Municipio)} />

        <TextField label="CNPJ/CPF" value={getValue(data?.cnpj_cpf)} />

        <TextField label="CRLV" value={getValue(data?.crlve)} />

        <TextField
          label="Dados do veículo"
          value={getValue(data?.dados_do_veiculo)}
        />

        <TextField
          label="PDF"
          value={
            <button
              style={{
                background: "transparent",
                border: "none",
                textDecoration: "underline",
                cursor: "pointer",
                color: "blue",
              }}
              onClick={() => openPDF(data?.url)}
            >
              Abrir PDF
            </button>
          }
        />
      </div>
    </div>
  );
};
