import { Image } from "primereact/image";
import { IAutomotivaLeilaoLocal } from "../../../../interface";
import styles from "./styles.module.css";

interface FieldProps {
  label: string;
  value: string;
}

const TextField = ({ label, value }: FieldProps) => {
  return (
    <div className={styles.dadosWrapper}>
      <p className={styles.text}>{label}:</p>
      <p className={`${styles.text} ${styles.value}`}>{`${value || "-"}`}</p>
    </div>
  );
};

export const AutomotivaLeilaoLocalPage = (data: IAutomotivaLeilaoLocal) => {
  const getValue = (value?: string): string => value || "Não informado";
  const result = data?.dadosLeilao;
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>Detalhes do veículo</p>
      </div>

      <div className={styles.listaWrapper}>
        {!!result && (
          <>
            <TextField label="Marca" value={getValue(result?.marca)} />
            <TextField label="Modelo" value={getValue(result?.modelo)} />
            <TextField
              label="Ano de Fabricação"
              value={getValue(result?.anoFabricacao?.toString())}
            />
            <TextField
              label="Ano do Modelo"
              value={getValue(result?.anoModelo?.toString())}
            />
            <TextField label="Cor" value={getValue(result?.cor)} />
            <TextField
              label="Combustível"
              value={getValue(result?.combustivel)}
            />
            <TextField label="Placa" value={getValue(result?.placa)} />
            <TextField label="Chassi" value={getValue(result?.chassi)} />
            <TextField label="Condição" value={getValue(result?.condicao)} />
            <TextField label="Lote" value={getValue(result?.lote)} />
            <TextField label="Leiloeiro" value={getValue(result?.leiloeiro)} />
            <TextField
              label="Data do Leilão"
              value={getValue(result?.dataLeilao)}
            />
            <TextField label="Comitente" value={getValue(result?.comitente)} />
          </>
        )}

        {/* {data?.ERRO && <p>{data?.ERRO}</p>} */}
      </div>

      <br />

      {result?.imagens && (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
          {result?.imagens.map((img, index) => (
            <div
              key={index}
              style={{
                maxWidth: "250px",
                height: "100px",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                src={img}
                alt="Image"
                zoomSrc={img}
                preview
                imageStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
