import { DataTable } from "primereact/datatable";
import { IRouboFurto } from "../../../../interface/RouboFurto";
import styles from "./styles.module.css";
import { Column } from "primereact/column";

export const RouboFurto = (response: IRouboFurto) => {
  const { RF } = response.RESPOSTA;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>Histórico de Roubo e Furto</p>
      </div>

      <div style={{ marginTop: "1.5rem" }}>
        <DataTable stripedRows value={RF}>
          <Column field="boletim" header="Numero do Boletim" />
          <Column
            field="categoria_ocorrencia"
            header="Categoria da Ocorrência"
          />
          <Column
            field="municipio_ocorrencia"
            header="Município da Ocorrência"
          />
          <Column field="data_ocorrencia" header="Data da Ocorrência" />
          <Column field="ano" header="Ano" />
          <Column field="orgao_seguranca" header="Órgão de Segurança" />
        </DataTable>
      </div>
    </div>
  );
};
