import { useTranslation } from "react-i18next";
import { Panel } from "primereact/panel";
import { Chip } from "primereact/chip";
import OutPutCard from "../../../components/outputCard";
import { Divider } from "primereact/divider";

export default function AutomotivaRenainf({
  automotivaRenainf,
}: {
  automotivaRenainf: any;
}) {
  const { t } = useTranslation();

  return (
    <Panel header={t("Automotiva Renainf")} style={{ width: "98%" }}>
      {!automotivaRenainf?.RETORNO?.MSG_ERRO && (
        <>
          <Divider align="left">
            <div className="flex-score justify-content-between justify-content-center">
              <span
                className="p-tag"
                style={{
                  position: "relative",
                  backgroundColor: "#183462",
                  fontSize: "1.1rem",
                  height: "2rem",
                  marginTop: "0.5rem",
                }}
              >
                Resumo do Veículo
              </span>
            </div>
          </Divider>
          <br></br>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados">{t("placa")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={automotivaRenainf?.RETORNO?.PLACA}
                />
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap"></div>
            <div className="col p-fluid flex flex-wrap"></div>
            <div className="col p-fluid flex flex-wrap"></div>
          </div>
          <br></br>
          <br></br>
          <Divider align="left">
            <span
              className="p-tag"
              style={{ backgroundColor: "#183462", fontSize: "1.1rem" }}
            >
              Renainf
            </span>
          </Divider>
          <br></br>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Valor Infração"
                  value={automotivaRenainf?.REGISTROS?.DETALHE_VALOR_INFRACAO}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Número Infração"
                  value={automotivaRenainf?.REGISTROS?.NUMEROAUTOINFRACAO}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Valor Pago"
                  value={
                    automotivaRenainf?.REGISTROS?.DADOSDOPAGAMENTO_VALOR_PAGO
                  }
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Órgão"
                  value={automotivaRenainf?.REGISTROS?.ORGAO}
                ></OutPutCard>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="UF Órgão Autuador"
                  value={
                    automotivaRenainf?.REGISTROS?.DETALHE_UF_ORGAO_AUTUADOR
                  }
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Infração"
                  value={automotivaRenainf?.REGISTROS?.DETALHE_COD_INFRACAO}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Cadastro Infração"
                  value={
                    automotivaRenainf?.REGISTROS?.DETALHE_CADASTRAMENTO_INFRACAO
                  }
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Data Infração"
                  value={automotivaRenainf?.REGISTROS?.DETALHE_DT_INFRACAO}
                ></OutPutCard>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Detalhe Número Infração"
                  value={
                    automotivaRenainf?.REGISTROS?.DETALHE_NUM_AUTO_INFRACAO
                  }
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Número Infração"
                  value={automotivaRenainf?.REGISTROS?.INFRACAO}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Local"
                  value={automotivaRenainf?.REGISTROS?.DETALHE_LOCAL_INFRACAO}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Órgão Autuador"
                  value={automotivaRenainf?.REGISTROS?.DETALHE_ORGAO_AUTUADOR}
                ></OutPutCard>
              </div>
            </div>
          </div>
        </>
      )}

      {automotivaRenainf?.RETORNO?.MSG_ERRO && (
        <p>{automotivaRenainf?.RETORNO?.MSG_ERRO}</p>
      )}
    </Panel>
  );
}
