import {
  ApiResponseAutomotivaBaseEstadual,
  DadosFormatados,
  AutomotivaBaseEstadualProps,
} from "./types";
import { Panel } from 'primereact/panel';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import './index.css';

	const formatarChave = (key: string): string => {
	  const formatacoes: { [key: string]: string } = {
		CHASSI: "Chassi:",
		CILINDRADA: "Cilindradas:",
		ESPECIE: "Espécie:",
		COMBUSTIVEL: "Combustível:",
		POTENCIA: "Potência:",
		MARCAMODELOCOMPLETO: "Modelo:",
		MARCA: "Marca:",
		PLACA: "Placa:",
		VEIANOFABR: "Ano Fabricação:",
		VEIANOMODELO: "Ano Modelo:",
		CAPACIDADEPASSAG: "Capacidade:",
		TIPO: "Tipo:",
		MUNICIPIO: "Município:",
		UF: "UF:",
		VEIPROCEDENCIA: "Procedência:",
		MOTOR: "Motor:",
		COR: "Cor:",
		RENAVAM: "Renavam:",
		CCOMUNICACAOVENDA: "Comunicado Venda:",
		DATAVENDA: "Data Venda:",
		CMT: "CMT:",
		DATAULTIMAATUALIZACAO: "Data Atualização:",
		TIPODOCUMENTOFATURADO: "Tipo Documento Faturado:",
		UFFATURADO: "UF Documento Faturado:",
		CPFCNPJFATURADO: "Documento Faturado:",
		CAIXACAMBIO: "Câmbio:",
		CAPACIDADECARGA: "Capacidade Carga:",
		PBT: "PBT:",
		EIXOS: "Quantidade de Eixos:",
		TIPOREMARCACAOCHASSI: "Situação Chassi:",
		RESRENAJUD: "Renajud:",
		RESTRICAOFINAN: "Restrição Financeira:",
		CPF_CNPJ_PROPRIETARIO: "Documento:",
		PRONOME: "Nome:",
		TIPODOCUMENTOPROPRIETARIO: "Tipo Documento Proprietário:",
		DATAEMISSAOCRV: "Data Emissão CRV:",
		DPVAT: "DPVAT:",
		RESTRIBUTARIA: "Restrição Tributária:",
		EXISTEDEBITODELICENCIAMENTO: "Licenciamento:",
		EXISTEDEBITODELICENCIAMENTOVL: "Valor Licenciamento:",
		EXISTEDEBITODEIPVA: "IPVA:",
		DEBIPVA: "Valor IPVA:",
		EXISTEDEBITOMULTA: "Multa:",
		VALORTOTALDEBITOMULTA: "Valor Multa:",
		TIPODOCCOMPRADOR: "Tipo do Comprador:",
		VEICATEGORIA: "Categoria:",
		TIPODOCUMENTOIMPORTADORA: "Tipo Documento Importadora:",
		TEMPOEXECUCAO: "Tempo Execução:",
		SITUACAOVEICULO: "Situação Veículo:",
		RESTRICAONOMEAGENTE: "Restrição Nome Agente:",
		RESTRICAODATAINCLUSAO: "Restrição Data Inclusão:",
		RESTRICAOCPFCNPJARRENDATAR: "Restrição CPF/CNPJ Arrendatário:",
		RESTRICAOARRENDATARIO: "Restrição Arrendatário:",
		RESJUDICIAL: "Restrição Judicial:",
		RESGUINCHO: "Restrição Guincho:",
		RESFURTO: "Restrição Furto:",
		RESAMBIENTAL: "Restrição Ambiental:",
		RESADMINISTRATIVA: "Restrição Administrativa:",
		REDUNDANCIA: "Redundância:",
		PROTOCOLODETRAN: "Protocolo Detran:",
		PRONOMEANTERIOR: "Pronome Anterior:",
		OUTRAS_RESTRICOES_04: "Outras Restrições 04:",
		OUTRAS_RESTRICOES_03: "Outras Restrições 03:",
		OUTRAS_RESTRICOES_02: "Outras Restrições 02:",
		OUTRAS_RESTRICOES_01: "Outras Restrições 01:",
		NUMERO_TERCEIROEIXO: "Número Terceiro Eixo:",
		NUMERO_EIXOTRASEIRODIF: "Número Eixo Traseiro Diferencial:",
		NUMERO_CARROCERIA: "Número Carroceria:",
		NUMERO_CAIXACAMBIO: "Número Caixa Câmbio:",
		NUMEROCONTRATOFINANCEIRA: "Número Contrato Financeira:",
		NOTAFISCAL: "Nota Fiscal:",
		MSG_ERRO: "Mensagem de Erro:",
		LICEXELIC: "Licenciamento Exe Licenciamento:",
		LICDATA: "Licenciamento Data:",
		INTENCAORESTRICAOFINAN: "Intenção Restrição Financiamento:",
		INTENCAONOMEFINANC: "Intenção Nome Financiamento:",
		INTENCAONOMEAGENTE: "Intenção Nome Agente:",
		INTENCAODATAINCLUSAO: "Intenção Data Inclusão:",
		INTENCAOCPFCNPJFINANC: "Intenção CPF/CNPJ Financiamento:",
		INSPECAOSTATUS: "Inspeção Status:",
		INSPECAOSELO: "Inspeção Selo:",
		INSPECAODATA: "Inspeção Data:",
		INSPECAOCENTRO: "Inspeção Centro:",
		INSPECAOANO: "Inspeção Ano:",
		EXISTE_ERRO: "Existe Erro:",
		EXISTEDEBITODEDPVAT: "Existe Débito de DPVAT?",
		DEBRENAINF: "Débito Renavam Informação:",
		DEBPOLRODFED: "Débito Polícia Rodoviária Federal:",
		DEBMUNICIPAIS: "Débito Municipais:",
		DEBDETRAN: "Débito Detran:",
		DEBDERSA: "Débito Dersa:",
		DEBDER: "Débito Der:",
		DEBCETESB: "Débito Cetesb:",
		DATAVIGENCIACONTRATOFINANCEIRA: "Data Vigência Contrato Financeira:",
		DATALIMITERESTRICAOTRIBUTARIA: "Data Limite Restrição Tributária:",
		DATAINCLUSAOINTENCAOTROCAFINANCEIRA: "Data Inclusão Intenção Troca Financiamento:",
		CPFCNPJCOMPRADOR: "Documento Comprador:",
		CODIGOTIPO: "Código Tipo:",
		CODIGOMARCA: "Código Marca:",
		CODIGOFINANCEIRA: "Código Financeira:",
		CODIGOCOR: "Código Cor:",
		CODIGOCATEGORIA: "Código Categoria:",
		CODIGOAGENTEFINANCEIRO: "Código Agente Financeiro:",
		CHAVERETORNO: "Chave Retorno:",
		CCOMUNICINCLUSAO: "Comunicação Inclusão:",
	  };
	  
	  return formatacoes[key] || key;
	};

const dadosSemArray = (dados: any, categoriaNome: string): DadosFormatados => {
  const dadosFormatados = Object.entries(dados).map(([key, value]) => ({
    label: formatarChave(key),
    value: value as string,
  }));

  return {
    categoria: categoriaNome,
    dados: dadosFormatados,
  };
};

const formatarDadosSemArray = (
  data: ApiResponseAutomotivaBaseEstadual
): DadosFormatados[] => {
  const resultado: DadosFormatados[] = [];

  if (data.RETORNO) 
    resultado.push(dadosSemArray(data.RETORNO, "Dados Gerais"));

  return resultado;
};

const formatarDadosComArray = (
  data: ApiResponseAutomotivaBaseEstadual
): JSX.Element[] => {
  const resultado: JSX.Element[] = [];
  if (data.DETALHAMENTO_DAS_MULTAS) {
    const renderizarArray = (rowData: any, field: string) => {
      const arrayData = rowData[field];
      return arrayData && Array.isArray(arrayData) && arrayData.length > 0
        ? arrayData.filter((item) => item !== null && item.trim() !== '').join(', ')
        : '-';
    };

    resultado.push(
        <DataTable size="normal" stripedRows value={Object.values(data.DETALHAMENTO_DAS_MULTAS)}>
          <Column field="AIT" header="AIT" />
          <Column field="DATA_HORA" header="Data" />
          <Column field="DATA_HORA_APRESENTACAO" header="Data Apresentação" />
          <Column field="DEFESA_DATA" header="Defesa Data" />
          <Column field="DEFESA_RESULTADO" header="Defesa Resultado" />
          <Column
            field="GUIA"
            header="Guia"
            body={(rowData) => renderizarArray(rowData, 'GUIA')}
          />
          <Column
            field="MUNICIPIO"
            header="Municipio"
            body={(rowData) => renderizarArray(rowData, 'MUNICIPIO')}
          />
          <Column field="INFRACAO" header="Infração" />
          <Column field="LOCAL" header="Local" />
          <Column field="ORGAO_AUTUADOR" header="Órgão Autuador" />
          <Column field="RECEITA_LOCAL" header="Receita Local" />
          <Column field="RENAINF" header="Informação Renavam" />
          <Column field="RESURSO" header="Recurso" />
          <Column field="RESURSO_RESULTADO" header="Resultado Recurso" />
          <Column field="SITUACAO" header="Situação" />
          <Column field="TIPIFICACAO" header="Tipificação" />
          <Column field="UF_ORGAO_AUTUADOR" header="UF Órgão Autuador" />
          <Column field="VALOR" header="Valor" />
          <Column field="VALOR_CORRIGIDO" header="Valor Corrigido" />
          <Column field="VALOR_DESCONTO" header="Valor Desconto" />
          <Column field="VALOR_PAGO" header="Valor Pago" />
          <Column field="VENCIMENTO" header="Vencimento" />
          <Column field="PLACA" header="Placa" />
          <Column field="REVANAM" header="Renavam" />
        </DataTable>
    );
  }

  return resultado;
};

export function AutomotivaBaseEstadual({
  data,
}: AutomotivaBaseEstadualProps) {
  const retorno = formatarDadosSemArray(data);
  const detalhamentoMultas = formatarDadosComArray(data);

  return (
    <>
      <Panel className="p-panel-header-green" header="Automotiva Base Estadual" style={{ width: '98%' }}>
        <Panel className="p-panel-header-green" header="Dados Gerais" toggleable>
			{retorno.map(({ categoria, dados }) => (
				<DataTable showGridlines size="normal" stripedRows value={dados} >
					<Column className="font-bold" field="label" header="Descrição"  />
					<Column field="value" header="Valor" />
				</DataTable>
			))}
        </Panel>
        <br></br>
        <Panel className="p-panel-header-green" header="Detalhamento das Multas" toggleable>
			{detalhamentoMultas.map((tabela) => (
			<div key={tabela.key}>{tabela}</div>
			))}  
        </Panel>
      </Panel>     
    </>
  );
}
