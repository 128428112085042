import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";
import PlanoList from './PlanoList';
import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import PlanoCadastrar from './PlanoCadastrar';
import { Messages } from 'primereact/messages';
import postPlano from "../../service/postPlano";
import putPlano from '../../service/putPlano';

export default function PlanoPage() {
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const [plano, setPlano] = useState<any | null>(null);
  const labelButton = t('cadastrar')
  const savePlano = (plano:any) => {
    if(plano.id){
      putPlano(plano).then(data => {
        if(data.includes('Erro')){
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
               
            ]);
          }
        }
        else{
          setPlano(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
               
            ]);
          }
        }
      })
    }
    else{
      postPlano(plano).then(data => {
        if(data.includes('Erro')){
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
               
            ]);
          }
        }
        else{
          setPlano(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
               
            ]);
          }
        }
      })
    }
    
    
  }
  const startContent = (
    <React.Fragment>
        <Button label={labelButton} icon="pi pi-plus" style={{backgroundColor:'#183462'}} onClick={() => {setPlano({})}}/>
    </React.Fragment>
);

  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-briefcase" style={{ fontSize: '2.0rem' }}></i> {t('planos')}</h1>
            </div>     
          </div>
      </div>
      <div className="card">
        <Messages ref={msgs} />
        {!plano && (
          <>
            <Toolbar start={startContent} />
            <br></br>
            <br></br>
            <PlanoList onEdit={(plano:any) => { setPlano(plano);}}></PlanoList>
          </>
        )}
        {plano && (
          <>
           <PlanoCadastrar plano={plano} onSave={savePlano} onClose={() => {setPlano(null)}}></PlanoCadastrar>
          </>
        )}
      </div>
                
    </Container>
  )
}
