import { Panel } from "primereact/panel";
import { IKarfexVeiculo } from "../../../interface/KarfexVeiculo";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InfoDisplay } from "../../../components/InfoDisplay";
import { isArray } from "lodash";
import { Chips } from "primereact/chips";
import { Chip } from "primereact/chip";

const convertToList = (data: any) => {
  const length = Object.keys(data?.resultado)?.length || 0;
  let list: any[] = [];

  if (length === 0) return list;

  for (let i = 0; i <= length; i++) {
    list = [
      ...list,
      {
        id: data?.resultado[i]?.id,
        status: data?.resultado[i]?.status,
        produto: data?.resultado[i]?.produto,
        resposta: data?.resultado[i]?.resposta,
      },
    ];
  }

  return list;
};

export const KarfexVeiculo = (response: IKarfexVeiculo) => {
  const karfexList = convertToList(response);

  return (
    <Panel header="Checklist">
      <div style={{ width: "100%", height: "600px", overflow: "auto" }}>
        {karfexList?.map((item) => (
          <div key={item?.id}>
            <p>{item?.produto}</p>
            {isArray(item?.resposta) ? (
              item?.resposta.map((resp: any) => (
                <div
                  key={resp?.cnpj}
                  style={{ width: "100%", display: "flex", gap: "24px" }}
                >
                  <InfoDisplay label="Instituição" value={resp?.instituicao} />
                  <InfoDisplay label="CNPJ" value={resp?.cnpj} />
                  <InfoDisplay
                    label="Ano/informação"
                    value={resp?.ano_informacao}
                  />
                </div>
              ))
            ) : (
              <Chip
                style={{
                  width: "100%",
                  display: "flex",
                  margin: 0,
                }}
                label={item?.resposta}
              />
            )}
          </div>
        ))}
      </div>
    </Panel>
  );
};
