import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import findVeiculoVenda from '../../service/findVeiculoVenda';
import { Button } from 'primereact/button';
import { SortOrder } from 'primereact/api';
import putVeiculoVendaComunicar from '../../service/putVeiculoVendaComunicar';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import { useGlobalState } from '../../components/globalState/GlobalStateProvider';
import { Nullable } from 'primereact/ts-helpers';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';



export default function VeiculoVendaList({onEdit, onDelete, onPrint, msgs}:{onEdit:(veiculoVenda:any)=>void, onDelete:(veiculoVenda:any)=>void, onPrint:(veiculoVenda:any)=>void, msgs:any}) {
  const { t } = useTranslation();
  const [veiculosVenda, setVeiculosVenda] = useState(undefined);
  const [erros, setErros] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);
  const {state} = useGlobalState();
  const [placa, setPlaca] = useState<Nullable<String | null>>(null);
  const [data, setData] = useState<Nullable<Date>>(new Date());
  const [dataFinal, setDataFinal] = useState<Nullable<Date>>(new Date());

  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0');
  }
  const formatDate = (date:Nullable<Date>) => {
    if(date){
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('-');
    }
   
  }

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    idFranquia : state.user.franquiaSelected?.id,
    data: formatDate(data),
    dataFinal: formatDate(dataFinal),
    placa: placa,
    sortField: 'dataVenda',
    sortOrder: SortOrder.DESC
  });


const cols = [
  { field: 'documento', header: 'Documento' },
  { field: 'nome', header: 'Nome' },
  { field: 'email', header: 'Email' },
  { field: 'inativo', header: 'Inativo' }
];
const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
const exportPdf = () => {
  import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
          const lazy = {
            first: 0,
            rows: totalRecords,
            page:  1,
            idFranquia : state.user.franquiaSelected?.id,
            sortField: 'placa',
            sortOrder: SortOrder.DESC
          }
          findVeiculoVenda(lazy).then(data => {
            const doc : any = new jsPDF.default('portrait');
            doc.autoTable(exportColumns, data.clientes);
            doc.save('clientes.pdf');
          });
          
      });
  });
};

const exportExcel = () => {
import('xlsx').then((xlsx) => {
  const lazy = {
    first: 0,
    rows: totalRecords,
    page:  1,
    idFranquia : state.user.franquiaSelected?.id,
    data: formatDate(data),
    dataFinal: formatDate(dataFinal),
    placa: placa,
    sortField: 'placa',
    sortOrder: SortOrder.DESC
  }
  findVeiculoVenda(lazy).then(data => {
    const users: any[] = []
    data.clientes.forEach((client:any) => users.push({nome:client.nome, email:client.email, documento:client.documento, inativo:client.inativo}))
    
    const worksheet = xlsx.utils.json_to_sheet(users);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
    });
    saveAsExcelFile(excelBuffer, 'clientes');
  });

    
});
};
const saveAsExcelFile = (excelBuffer: any, fileName: string) => {
import('file-saver').then((module) => {
    if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
});
};

const header = (
  <div className="flex-consulta align-items-center justify-content-end gap-2">
      <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
      <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
  </div>
);




  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: (event.first / event.rows) + 1,
      idFranquia : state.user.franquiaSelected?.id,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      placa: placa,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };
  const onSort = (event:any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      idFranquia : state.user.franquiaSelected?.id,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      placa: placa,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  const changePlaca = (pla:Nullable<String>) => {
    pla = pla ? pla : null
      setPlaca(pla);
      setlazyState({
        first: 0,
        rows: 10,
        page: 1,
        idFranquia : state.user.franquiaSelected?.id,
        data: formatDate(data),
        dataFinal: formatDate(dataFinal),
        placa: pla,
        sortField: 'dataVenda',
        sortOrder: SortOrder.DESC
      });
    
  }

  const changeData = (data:Nullable<Date>) => {
    if(data){
      setData(data);
      setData(data);
      setlazyState({
        first: 0,
        rows: 10,
        page: 1,
        idFranquia : state.user.franquiaSelected?.id,
        data: formatDate(data),
        dataFinal: formatDate(dataFinal),
        placa: placa,
        sortField: 'dataVenda',
        sortOrder: SortOrder.DESC
      });
     
    }
  }

  const changeDataFinal = (dataFi:Nullable<Date>) => {
    if(dataFi){
      setDataFinal(dataFi);
      setlazyState({
        first: 0,
        rows: 10,
        page: 1,
        idFranquia : state.user.franquiaSelected?.id,
        data: formatDate(data),
        dataFinal: formatDate(dataFi),
        placa: placa,
        sortField: 'dataVenda',
        sortOrder: SortOrder.DESC
      });
    }
  }

  const dateBodyTemplate = (rowData: { dataVenda: any; }) => {
    return formatDate(rowData.dataVenda);
  };

  const erroBodyTemplate = (rowData:string) => {
    return rowData;
  };

  const valueBodyTemplate = (rowData: { valor: any; }) => {
    return rowData.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
  };

  const loadLazyData = () => {
    findVeiculoVenda(lazyState).then(data => {setTotalRecords(data.total);setVeiculosVenda(data.veiculosVenda)}).catch(() => {setTotalRecords(0);setVeiculosVenda(undefined)});
  }

  const enviar = (venda:any) => {
    putVeiculoVendaComunicar(venda)
    .then((data) => {
      loadLazyData()
      msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
         
      ]);
    })
    .catch((erro) => {
      msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: erro, closable: false }
         
      ]);
    });
  }

  const startContent = (
    <React.Fragment>
      <div  style={{background:'transparent'}}>
        <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <label htmlFor="data" className="font-bold block mb-2">{t('dataInicial')} </label>
                  <Calendar id='data' value={data} onChange={(e) => changeData(e.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                </div>
              </div>
        </div>
        <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div>
                <div className="flex-auto">
                        <label htmlFor="nome" className="font-bold block mb-2">{t('placa')} </label>
                        <InputText id="nome" onBlur={(e)=>changePlaca(e.target.value)} style={{width: '20rem'}} required></InputText>
                    </div>
                </div>
              </div>
        </div>
      
      </div>
      <div style={{background:'transparent', marginLeft:'10rem'}}>
        <div className="grid">
              
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <label htmlFor="dataFinal" className="font-bold block mb-2">{t('dataFinal')} </label>
                  <Calendar id='dataFinal' value={dataFinal} onChange={(e) => changeDataFinal(e.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                </div>
              </div>
        </div>
        <div className="grid">
              
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <div className="flex-auto" style={{visibility:'hidden'}}>
                        <label htmlFor="nome" className="font-bold block mb-2">{t('placa')} </label>
                        <InputText id="nome" onBlur={(e)=>changePlaca(e.target.value)} style={{width: '20rem'}} required></InputText>
                    </div>
                </div>
              </div>
        </div>
     
      </div>
      
      
    </React.Fragment>
  );

  const actionTemplate = (venda:any) => {
    return (
       <>
      { venda.status === 'Incluído' && (
        <Button icon="pi pi-cloud-upload" onClick={() => {enviar(venda)}} style={{background:'transparent', color:'gray', border: 'none'}} />
     )}
     { venda.status === 'Erro' && (
        <>
          <Button icon="pi pi-search" onClick={() => {console.log(venda.erros);setErros(venda.erros)}} style={{background:'transparent', color:'gray', border: 'none'}} />
          <Button icon="pi pi-pencil" onClick={() => onEdit(venda)} style={{background:'transparent', color:'gray', border: 'none'}} />
        </>
     )}
     { venda.status === 'Enviado' && (
        <>
          <Button icon="pi pi-print" onClick={() => {onPrint(venda)}} style={{background:'transparent', color:'gray', border: 'none'}} />
          <Button icon="pi pi-trash" onClick={() => onDelete(venda)} style={{background:'transparent', color:'gray', border: 'none'}} />
        </>
        
      )}
     </>
    );
  };

  const footerContent = (
    <React.Fragment>
         
          <Button label="Fechar" icon="pi pi-times" style={{color:'#183462'}} onClick={() => resetForm()} className="p-button-text" />
    </React.Fragment>
    );

  const resetForm = () => {
    setErros(undefined);
  }  
  
  useEffect(() => {
    loadLazyData();
  },[lazyState])

  return (
    <div>
      <div>
       <Toolbar start={startContent} />
        <br></br>
        <br></br>
        <DataTable value={veiculosVenda} first={lazyState.first} dataKey="id" paginator={true}  lazy={true}
                        emptyMessage="Sem registros" header={header}
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows} totalRecords={totalRecords} onPage={onPage}
                        tableStyle={{ minWidth: '75rem' }}>
           <Column  body={dateBodyTemplate} sortable sortField='dataVenda' dataType="Date" header={t('dataVenda')}></Column>
           <Column field="placa" sortable header={t('placa')}  />
           <Column field="tipoSolicitante" sortable header={t('solicitante')}  />
           <Column body={valueBodyTemplate} sortable header={t('valor')}  />
           <Column field="status" sortable header={t('status')}  />
           <Column body={actionTemplate} />
        </DataTable> 
      </div>
      <Dialog header={t('erros')} visible={erros} style={{ width: '80vw' }} onHide={() => resetForm()} footer={footerContent}>
        <DataTable size="small" value={erros} tableStyle={{ minWidth: '50rem' }}>
           <Column  body={erroBodyTemplate}  header={t('erro')}></Column>
         </DataTable> 
      </Dialog>
    </div>
  )
}


