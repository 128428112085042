import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect, useState } from 'react';
import findFatura from '../../service/findFatura';
import { SortOrder } from 'primereact/api';
import { useGlobalState } from '../../components/globalState/GlobalStateProvider';


export default function HistoricoPage() {
  const { t } = useTranslation();
  const [faturas, setFaturas] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);
  const [expandedRows, setExpandedRows] = useState(undefined);
  const {state} = useGlobalState();
  
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    idFranquia : state.user.franquiaSelected?.id,
    sortField: 'dataVencimento',
    sortOrder: SortOrder.DESC
  });

  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: (event.first / event.rows) + 1,
      idFranquia : state.user.franquiaSelected?.id,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  const onSort = (event:any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      idFranquia : state.user.franquiaSelected?.id,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  const rowExpansionTemplate = (data:any) => {
    
    return (
        <div>
            <h5>Itens Fatura</h5>
            <DataTable value={data.itens}>
                <Column field="servico" header={t('servico')} sortable></Column>
                <Column field="quantidade" header={t('quantidade')} sortable></Column>
                <Column field="valor" header={t('valor')} sortable></Column>
                <Column field="total" header={t('total')} sortable></Column>
            </DataTable>
        </div>
    );
  };

  const loadLazyData = () => {
    findFatura(lazyState).then(data => {setTotalRecords(data.total);setFaturas(data.faturas)}).catch(() => {setTotalRecords(0);setFaturas(undefined)});
  }
  
  useEffect(() => {
    loadLazyData();
  },[lazyState])

  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-dollar" style={{ fontSize: '2.0rem' }}></i> {t('faturas')}</h1>
            </div>     
          </div>
      </div>
      <div className="card">
       
        <DataTable value={faturas} first={lazyState.first} dataKey="id" paginator={true}  lazy={true}
                        emptyMessage="Sem registros" expandedRows={expandedRows} onRowToggle={(e:any) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows} totalRecords={totalRecords} onPage={onPage}
                        tableStyle={{ minWidth: '75rem' }}>
           <Column expander={true} style={{ width: '1.5rem' }} />              
           <Column field="cliente" sortable header={t('cliente')}  />
           <Column field="dataReferencia" sortable header={t('dataReferencia')}  />
           <Column field="dataVencimento" sortable header={t('dataVencimento')}  />
           <Column field="valorMinimo" sortable header={t('vcm')}  /> 
           <Column field="valorConsumo" sortable header={t('valorConsumo')}  /> 
           <Column field="valorComplementar" sortable header={t('valorComplementar')}  /> 
           <Column field="valor" sortable header={t('valor')}  /> 
          
        </DataTable> 
      </div>
                
    </Container>
  )
}
