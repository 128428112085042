import { useEffect, useRef, useState } from "react";
import Container from "../../components/menu/Container";
import { useTranslation } from "react-i18next";
import "./Consulta.css";
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown";
import FiltroPF from "./filtro/FiltroPF";
import FiltroPJ from "./filtro/FiltroPJ";
import FiltroAutomotiva from "./filtro/FiltroAutomotiva";
import { FilterWeb } from "../../interface/FilterWeb";
import postConsultaWebService from "../../service/postConsultaWeb/postConsultaWebService";
import Resposta from "./Resposta";
import getCache from "../../service/getCache";
import { useNavigate } from "react-router";
import { Messages } from "primereact/messages";
import getUserCheck from "../../service/getUserCheck";
import { useGlobalState } from "../../components/globalState/GlobalStateProvider";

export default function ConsultaPage() {
  const { state } = useGlobalState();
  const { t } = useTranslation();
  const [resposta, setResposta] = useState<any | null>(null);
  const [original, setOriginal] = useState<boolean>(true);
  const [consultaCredito, setConsultaCredito] = useState(
    t("consultasCadastrais")
  );
  const navigate = useNavigate();
  const initialized = useRef(false);
  const msgs = useRef<Messages>(null);

  const handleCloseFilter = () => {
    setselectedTipoConsulta("pf");
    setResposta(null);
    navigate("/consultar");
  };

  const validIndicadores = (filterWeb: FilterWeb): boolean => {
    let retorno = true;
    if (filterWeb.indicadores.length != 0) {
      filterWeb.indicadores.map((item) => {
        if (item.target.length === 0) {
          retorno = false;
        }
      });
    }
    return retorno;
  };

  const handleProcessFilter = (filterWeb: FilterWeb) => {
    msgs.current?.clear();
    if (
      filterWeb.consultas.length > 0 &&
      (filterWeb.consultas.includes("QUODVERIFIQPJ_MAISNEGOCIO") ||
        filterWeb.consultas.includes("QUODMAISNEGOCIOPJ")) &&
      (!filterWeb.valor ||
        filterWeb.valor < 1 ||
        (!filterWeb.arrojado && !filterWeb.usual && !filterWeb.conservador))
    ) {
      window.scrollTo(0, 0);
      msgs.current?.clear();
      msgs.current?.show([
        {
          sticky: false,
          life: 100000,
          severity: "error",
          summary: "Erro",
          detail: "O valor e o perfil de risco são obrigatórios",
          closable: false,
        },
      ]);
    } else {
      if (!validIndicadores(filterWeb)) {
        window.scrollTo(0, 0);
        msgs.current?.clear();
        msgs.current?.show([
          {
            sticky: false,
            life: 100000,
            severity: "error",
            summary: "Erro",
            detail: "Indicadores não Selecionados",
            closable: false,
          },
        ]);
      } else {
        if (
          (selectedTipoConsulta === "auto" || filterWeb.documento) &&
          filterWeb.consultas &&
          filterWeb.consultas.length > 0
        ) {
          if (filterWeb.indicadores) {
            const indicadores: any[] = [];
            filterWeb.indicadores.map((item: any) => {
              item.target.map((i: any) => indicadores.push(i.value));
            });
            filterWeb.indicadores = indicadores;
          }
          const findLabel = (consultas: Array<any>, value: string): string => {
            for (const consulta of consultas) {
              if (consulta.value === value) {
                return consulta.label;
              }
              if (consulta.permissoes && consulta.permissoes.length > 0) {
                const foundLabel = findLabel(consulta.permissoes, value);
                if (foundLabel !== value) {
                  return foundLabel;
                }
              }
            }
            return value;
          };
          const consultasLabels = filterWeb.consultas.map((consultaKey) =>
            findLabel(state.user.consultas, consultaKey)
          );
          setConsultaCredito(
            `${t("consultasCadastrais")} - ${consultasLabels.join(", ")}`
          );
          postConsultaWebService(filterWeb)
            .then((data:any) => {
              if(data.resumosAutomotiva){
                window.scrollTo(0, 450);
              }
              else{
                window.scrollTo(0, 0);
              }
              setResposta(data);
              setselectedTipoConsulta("pf");
            })
            .catch((error) => {
              window.scrollTo(0, 0);
              msgs.current?.clear();
              msgs.current?.show([
                {
                  sticky: false,
                  life: 100000,
                  severity: "error",
                  summary: "Erro",
                  detail: "Erro ao realizar consulta",
                  closable: false,
                },
              ]);
            });
        } else {
          window.scrollTo(0, 0);
          msgs.current?.clear();
          msgs.current?.show([
            {
              sticky: false,
              life: 100000,
              severity: "error",
              summary: "Erro",
              detail:
                (selectedTipoConsulta === "auto" ? "" : "Documento e") +
                " opção de consulta são obrigatórios",
              closable: false,
            },
          ]);
        }
      }
    }
  };
  const [selectedTipoConsulta, setselectedTipoConsulta] = useState("pf");
  const tiposConsulta = [
    { name: "Selecione", code: null },
    { name: "Consulta Pessoa Física", code: "pf" },
    { name: "Consulta Pessoa Jurídica", code: "pj" },
    { name: "Consulta Automotiva", code: "auto" },
  ];

  const findCacheById = (id: string) => {
    if (id && !id.includes("consultar")) {
      getCache(id).then((data:any) => {
        if(data.resumosAutomotiva){
          window.scrollTo(0, 450);
        }
        else{
          window.scrollTo(0, 0);
        }
       
        setResposta(data);
      });
    }
  };

  useEffect(() => {
    getUserCheck().then((response) => console.log(response));
    if (!initialized.current) {
      initialized.current = true;
      const resp = localStorage.getItem("resposta");
      if (resp) {
        localStorage.removeItem("resposta");
        setOriginal(false);
        setResposta(JSON.parse(resp));
      }
      findCacheById(
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ]
      );
    }
  }, []);

  return (
    <Container>
      <h1
        style={{
          marginTop: "76px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <i className="pi pi-search-plus" style={{ fontSize: "2rem" }}></i>
        {consultaCredito}
      </h1>

      <div className="row">
        <div className="col-lg-6 mb-3" style={{ width: "60%" }}>
          <Messages ref={msgs} />
          {resposta == null && (
            <Panel header={t("opcaoConsulta")}>
              <Dropdown
                value={selectedTipoConsulta}
                onChange={(e) => setselectedTipoConsulta(e.value)}
                options={tiposConsulta}
                optionLabel="name"
                placeholder="Selecione o tipo Consulta"
                optionValue="code"
                className="w-full md:w-14rem"
              />
            </Panel>
          )}
          <br></br>
          {selectedTipoConsulta === "pf" && resposta == null && (
            <FiltroPF
              msgs={msgs}
              onProcess={handleProcessFilter}
              onClose={handleCloseFilter}
            ></FiltroPF>
          )}

          {selectedTipoConsulta === "pj" && resposta == null && (
            <FiltroPJ
              msgs={msgs}
              onProcess={handleProcessFilter}
              onClose={handleCloseFilter}
            ></FiltroPJ>
          )}

          {selectedTipoConsulta === "auto" && resposta == null && (
            <FiltroAutomotiva
              msgs={msgs}
              onProcess={handleProcessFilter}
              onClose={handleCloseFilter}
            ></FiltroAutomotiva>
          )}
        </div>
      </div>

      <div>
        {resposta != null && (
          <Resposta
            original={original}
            resposta={resposta}
            onClose={handleCloseFilter}
          ></Resposta>
        )}
      </div>
    </Container>
  );
}
