import { Panel } from "primereact/panel";
import { IProdutoDocumentoCNH } from "../../../interface/ProdutoDocumentoCNH";
import { InfoDisplay } from "../../../components/InfoDisplay";

export const ProdutoDocumentoCNH = (response: IProdutoDocumentoCNH) => {
  const { REGISTRO_1 } = response?.CNHCHAVETRIPLA;
  return (
    <div>
      <Panel header="Dados do Documento CNH">
        {REGISTRO_1 && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "24px",
            }}
          >
            <InfoDisplay
              label="Nome do Titular"
              value={REGISTRO_1?.NOME || "Não informado"}
            />
            <InfoDisplay
              label="CPF"
              value={REGISTRO_1?.CPF || "Não informado"}
            />
            <InfoDisplay
              label="Data de Nascimento"
              value={REGISTRO_1?.DATANASCIMENTO || "Não informado"}
            />
            <InfoDisplay
              label="Nome da Mãe"
              value={REGISTRO_1?.NOMEMAE || "Não informado"}
            />
            <InfoDisplay
              label="Nome do Pai"
              value={REGISTRO_1?.NOMEPAI || "Não informado"}
            />
            <InfoDisplay
              label="Data da Primeira Habilitação"
              value={REGISTRO_1?.DATAPRIMEIRAHABILITACAO || "Não informado"}
            />
            <InfoDisplay
              label="UF da Primeira Habilitação"
              value={REGISTRO_1?.UFPRIMEIRAHABILITACAO || "Não informado"}
            />
            <InfoDisplay
              label="Data de Validade da CNH"
              value={REGISTRO_1?.DATAVALIDADECNH || "Não informado"}
            />
            <InfoDisplay
              label="Data da Última Emissão"
              value={REGISTRO_1?.DATAULTIMAEMISSAO || "Não informado"}
            />
            <InfoDisplay
              label="Categoria"
              value={REGISTRO_1?.CATEGORIAATUAL || "Não informado"}
            />
            <InfoDisplay
              label="Situação"
              value={REGISTRO_1?.SITUACAO || "Não informado"}
            />
            <InfoDisplay
              label="UF da Habilitação Atual"
              value={REGISTRO_1?.UFHABILITACAOATUAL || "Não informado"}
            />
            <InfoDisplay
              label="Número da CNH"
              value={REGISTRO_1?.CNH || "Não informado"}
            />
            <InfoDisplay
              label="Número do Formulário RENACH"
              value={REGISTRO_1?.NUMEROFORMULARIORENACH || "Não informado"}
            />
          </div>
        )}
      </Panel>
    </div>
  );
};
