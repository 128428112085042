import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import { ScrollTop } from "primereact/scrolltop";
import { Toolbar } from "primereact/toolbar";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import logo from "../../assets/new-logo-car.png";
import { AlertaStatus } from "../../components/AlertaStatus";
import Acoes from "./resposta/Acoes";
import AcoesCompleta from "./resposta/AcoesCompleta";
import Analise from "./resposta/Analise";
import Apontamentos from "./resposta/Apontamentos";
import { AutocorpLeilao } from "./resposta/AutoCorpLeilao";
import AutomotivaAgregado from "./resposta/AutomotivaAgregado";
import { AutomotivaCSVPage } from "./resposta/AutomotivaCSV";
import { AutomotivaFipePage } from "./resposta/AutomotivaFipe";
import AutomotivaGravame from "./resposta/AutomotivaGravame";
import AutomotivaMotor from "./resposta/AutomotivaMotor";
import AutomotivaPrecificador from "./resposta/AutomotivaPrecificador";
import AutomotivaRenajud from "./resposta/Renajud";
import AutomotivaRenavam from "./resposta/AutomotivaRenavam";
import AutomotivaHistoricoProprietario from "./resposta/AutomotivaHistoricoProprietario";
import AutomotivaLeilao from "./resposta/AutomotivaLeilao";
import { AutomotivaLeilaoLocalPage } from "./resposta/AutomotivaLeilaoLocal";
import AutomotivaOnline from "./resposta/AutomotivaOnline";
import AutomotivaRenainf from "./resposta/AutomotivaRenainf";
import { AutomotivaVeiculoProprietario } from "./resposta/AutomotivaVeiculoProprietario";
import Aviso from "./resposta/Aviso";
import Cadastral from "./resposta/Cadastral";
import { CadastralEmailPJ } from "./resposta/CadastralEmailPJ";
import CadastralNomePF from "./resposta/CadastralNomePF";
import CadastralPesquisaPJ from "./resposta/CadastralPesquisaPJ";
import { CadastralTelefonePJ } from "./resposta/CadastralTelefonePJ";
import { CadastralPFEmail } from "./resposta/CadastraPFEmail";
import CCFS from "./resposta/CCFS";
import CompletaPF from "./resposta/CompletaPF";
import CompletaPJ from "./resposta/CompletaPJ";
import ComportamentoFinanceiro from "./resposta/ComportamentoFinanceiro";
import { ConsultaBaseLeilao } from "./resposta/ConsultaBaseLeilao";
import { AutomotivaBaseEstadual } from "./resposta/AutomotivaBaseEstadual";
import { Credify } from "./resposta/Credify";
import { CredifyConsultaCPF } from "./resposta/CredifyConsultaCPF";
import { CredifyPfTelefone } from "./resposta/CredifyPfTelefone";
import { CredifyPrecificadorFipe } from "./resposta/CredifyPrecificadorFipe";
import DadosConsulta from "./resposta/DadosConsulta";
import DadosConsultaPJ from "./resposta/DadosConsultaPJ";
import Empresas from "./resposta/Empresas";
import EnderecoHist from "./resposta/EnderecoHist";
import Endividamento from "./resposta/Endividamento";
import FaturamentoPresumido from "./resposta/FaturamentoPresumido";
import { Gps353 } from "./resposta/Gps353";
import { Gps391 } from "./resposta/Gps391";
import HistoricoPagamento from "./resposta/HistoricoPagamento";
import HistoricoInfo from "./resposta/HistorioInfo";
import IndicadorNegocio from "./resposta/IndicadorNegocio";
import IndicadorPJ from "./resposta/IndicadorPJ";
import Informativo from "./resposta/informativo/Informativo";
import { KarfexVeiculo } from "./resposta/KarfexVeiculo";
import LimiteCreditoPJ from "./resposta/LimiteCreditoPJ";
import LinhaCredito from "./resposta/LinhaCredito";
import ListaConsulta from "./resposta/ListaConsulta";
import MaisNegocio from "./resposta/MaisNegocio";
import Negativacoes from "./resposta/Negativacoes";
import ParticipacaoEmpresa from "./resposta/ParticipacaoEmpresa";
import Pendencias from "./resposta/Pendencias";
import PontualidadePagamento from "./resposta/PontualidadePagamento";
import { ProdutoDocumentoCNH } from "./resposta/ProdutoDocumentoCNH";
import { ProprietarioTelefoneOperadora } from "./resposta/ProprietarioTelefoneOperadora";
import ProtestoQuod from "./resposta/ProtestoQuod";
import Protestos from "./resposta/Protestos";
import RendaPresumida from "./resposta/RendaPresumida";
import { RouboFurto } from "./resposta/RouboFurto";
import Score from "./resposta/Score";
import ScoreGrauAtvidadePJ from "./resposta/ScoreGrauAtividadePJ";
import Socios from "./resposta/Socios";
import { AutomotivaCRLVPage } from "./resposta/AutomotivaCRLV";
import { CreditoSimples } from "./resposta/CreditoSimples";
import { AutomotivaSinistroPage } from "./resposta/AutomotivaSinistro";
import { AutomotivaIndicioSinistro01 }  from "./resposta/AutomotivaIndicioSinistro01";
import { CadastralChequeContumacia }  from "./resposta/CadastralChequeContumacia";
import { AutomotivaAlertaVeicular } from "./resposta/AutomotivaAlertaVeicular";
import { BacenScoreSCRPage } from "./resposta/BacenScoreSCR";


export default function ConsultaPage({
  resposta,
  onClose,
  original,
}: {
  resposta: any;
  onClose: () => void;
  original: boolean;
}) {
  const { t } = useTranslation();
  const print = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => print.current,
  });

  const startContent = (
    <React.Fragment>
      <Button
        label="Imprimir"
        icon="pi pi-print"
        style={{ backgroundColor: "#183462" }}
        onClick={() => {
          handlePrint();
        }}
      />
    </React.Fragment>
  );

  const endContent = (
    <React.Fragment>
      <Button
        icon="pi pi-times"
        onClick={onClose}
        rounded
        outlined
        severity="danger"
        aria-label="Cancel"
      />
    </React.Fragment>
  );

  const RodapeMensagem = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          padding: "24px 0px",
        }}
      >
        <div>
          <h3 style={{ margin: 0 }}>INFORMAÇÕES IMPORTANTES:</h3>
          <p style={{ margin: 0 }}>
            <b>Confidencialidade e Uso das Informações:</b> As informações
            contidas neste mensagem são confidenciais e deverão ser utilizadas
            exclusivamente para a orientação das transações comerciais
            relacionadas ao CNPJ: 28.932.263/0001-58. Qualquer uso em desacordo
            com a legislação vigente, incluindo a Lei Geral de Proteção de Dados
            (Lei 13.709/18 - LGPD), poderá acarretar responsabilidade civil e
            criminal pelos danos causados a terceiros. A responsabilidade da
            Carro Positivo se limita à transmissão fiel dos dados obtidos de
            veículos automotores, registrados em bases de dados públicas e
            privadas. As informações exibidas nesta consulta refletem o conteúdo
            disponível na data e hora da pesquisa e são de responsabilidade do
            CNPJ: 28.932.263/0001-58. Em caso de dúvida ou divergência,
            recomenda-se consultar diretamente o órgão competente. O CNPJ
            28.932.263/0001-58 declara estar ciente de que todas as informações
            fornecidas nesta consulta têm o objetivo de auxiliar na verificação
            da procedência do veículo, servindo apenas como uma ferramenta de
            análise preliminar, e não como a única base para tomada de decisão
            em processos de vistoria ou comercialização do veículo. Importante
            destacar que o levantamento de informações por meio de consulta
            eletrônica não substitui a consulta ao órgão oficial competente,
            especialmente porque as informações podem ser alteradas, incluídas
            ou removidas em tempo real por órgãos públicos, instituições
            financeiras e seguradoras. Veículos cadastrados em outros estados
            podem apresentar vícios ocultos, restrições, débitos ou bloqueios
            que só serão identificados no prontuário do veículo no momento da
            transferência efetiva para o novo estado. As informações mencionadas
            são de uso exclusivo do destinatário e estão protegidas por sigilo
            contratual. Qualquer uso por terceiros ou para finalidades distintas
            daquelas acordadas caracteriza ilícito civil, invalidando a prova
            para efeitos processuais.
          </p>
        </div>
        <div>
          <h3 style={{ margin: 0 }}>AVISO:</h3>
          <p style={{ margin: 0 }}>
            Ainda que empregando os melhores esforcos, nossa empresa se exime de
            qualquer responsabilidade pela eventual nao inclusao de algum
            registro em razao de atraso ou falta do encaminhamento dos dados
            pelos diversos provedores de informacoes conveniados. Em caso de
            duvidas entre em contato com nosso suporte.
          </p>
        </div>
        <div>
          <h3 style={{ margin: 0 }}>MINUTA DE DECLARAÇÃO LGPD:</h3>
          <p style={{ margin: 0 }}>
            Declaro que a utilização dos dados respeitará as finalidades e
            procedimentos legalmente admitidos pela Lei Geral de Proteção de
            Dados divulgados em minha página inicial de acesso.
          </p>
        </div>
      </div>
    );
  };

  const isAuto = Object.entries(resposta).some(
    ([key]) =>
      key.includes("auto") ||
      key.includes("historicoRouboFurto") ||
      key.includes("placaFipe")
  );

  return (
    <>
      <Toolbar style={{ width: "98%" }} start={startContent} end={endContent} />
      <br></br>
      <br></br>
      <div ref={print} className="flex flex-column align-items-center">
        <style type="text/css" media="print">
          {
            "\
  @page { size: portrait; font-size: 6pt; .label-dados {font-size: 6pt;}; margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }\
"
          }
        </style>

        <Card style={{ width: "98%" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "40px",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img src={logo} style={{ width: "250px" }} alt="Logo" />
            </div>

            <div
              style={{ textAlign: "center" }}
              className="col p-fluid flex flex-wrap"
            >
              <h1>{resposta.informativo.opcoesPai.join(" / ")}</h1>
              <h5>{resposta.informativo.opcoes.join(" / ")}</h5>
            </div>

            <div>
              <img src={logo} style={{ width: "250px" }} alt="Logo" />
            </div>
          </div>
        </Card>
        <br></br>
        <br></br>
        <Informativo informative={resposta.informativo}></Informativo>
        {resposta?.resumosAutomotiva && (
          <AlertaStatus
            date={resposta.informativo.data}
            data={resposta?.resumosAutomotiva}
          />
        )}
        <br></br>
        {resposta.avisos.length > 0 && <Aviso avisos={resposta.avisos}></Aviso>}
        <br></br>
        {resposta.dadosConsulta && (
          <DadosConsulta dadosConsulta={resposta.dadosConsulta}></DadosConsulta>
        )}
        {resposta.dadosConsultaPJ && (
          <DadosConsultaPJ
            capitalSocial={resposta.capitalSocial}
            dadosConsultaPJ={resposta.dadosConsultaPJ}
          ></DadosConsultaPJ>
        )}
        {resposta.pessoa && (
          <>
            {" "}
            <br></br>
            <Cadastral pessoa={resposta.pessoa}></Cadastral>
          </>
        )}
        {resposta.completaPF && (
          <CompletaPF completaPF={resposta.completaPF}></CompletaPF>
        )}
        {resposta.completaPJ && (
          <CompletaPJ completaPJ={resposta.completaPJ}></CompletaPJ>
        )}
        {resposta.cadastralPesquisaPJ && (
          <CadastralPesquisaPJ
            cadastralPesquisaPJ={resposta.cadastralPesquisaPJ}
          ></CadastralPesquisaPJ>
        )}
        {resposta.score && (
          <>
            <br></br>
            <Score
              scoreBoleto={false}
              label="Score"
              score={resposta.score}
            ></Score>
          </>
        )}
        {resposta.scoreBoletoPJ && (
          <>
            <br></br>
            <Score
              scoreBoleto={true}
              label="Score Boleto PJ"
              score={resposta.scoreBoletoPJ}
            ></Score>
          </>
        )}
        {resposta.acoes && resposta.acoes.length > 0 && (
          <>
            <br></br>
            <Acoes acoes={resposta.acoes}></Acoes>
          </>
        )}
        {resposta.acoesCompleta && resposta.acoesCompleta.length > 0 && (
          <>
            <br></br>
            <AcoesCompleta acoes={resposta.acoesCompleta}></AcoesCompleta>
          </>
        )}
        {resposta.showProtesto && (
          <>
            <br></br>
            <Protestos cartorios={resposta.cartorios}></Protestos>
          </>
        )}
        {resposta.showApontamento && (
          <>
            <br></br>
            <Apontamentos apontamentos={resposta.apontamentos}></Apontamentos>
          </>
        )}
        {resposta.protestosQuod && resposta.protestosQuod.length > 0 && (
          <>
            <br></br>
            <ProtestoQuod protestos={resposta.protestosQuod}></ProtestoQuod>
          </>
        )}
        {resposta.pendencias && resposta.pendencias.length > 0 && (
          <>
            <br></br>
            <Pendencias pendencias={resposta.pendencias}></Pendencias>
          </>
        )}
        {resposta.showPendencia > 0 && (
          <>
            <br></br>
            <Negativacoes negativacoes={resposta.negativacoes}></Negativacoes>
          </>
        )}
        {resposta.creditoSimples && (
          <CreditoSimples data={resposta.creditoSimples} />
        )}
        {resposta.automotivaindiciodesinistro01 && (
          <AutomotivaIndicioSinistro01 data={resposta.automotivaindiciodesinistro01} />
        )}      
        {resposta.cadastralchequecontumacia && (
          <CadastralChequeContumacia data={resposta.cadastralchequecontumacia} />
        )}       
          
        {resposta.ccfs && resposta.ccfs.length > 0 && (
          <>
            <br></br>
            <CCFS ccfs={resposta.ccfs}></CCFS>
          </>
        )}
        {resposta.rendaPresumida && (
          <>
            <br></br>
            <RendaPresumida
              rendaPresumida={resposta.rendaPresumida}
            ></RendaPresumida>
          </>
        )}
        {resposta.scoreGrauAtividadePJ && (
          <>
            <br></br>
            <ScoreGrauAtvidadePJ
              scoreGrauAtividadePJ={resposta.scoreGrauAtividadePJ}
            ></ScoreGrauAtvidadePJ>
          </>
        )}
        {resposta.comportamentoFinanceiro && (
          <>
            <br></br>
            <ComportamentoFinanceiro
              comportamento={resposta.comportamentoFinanceiro}
            ></ComportamentoFinanceiro>
          </>
        )}
        {resposta.linhaCredito && (
          <>
            <br></br>
            <LinhaCredito linhaCredito={resposta.linhaCredito}></LinhaCredito>
          </>
        )}
        {resposta.endividamentos && resposta.endividamentos.length > 0 && (
          <>
            <br></br>
            <Endividamento
              endividamentos={resposta.endividamentos}
            ></Endividamento>
          </>
        )}
        {resposta.pontualidadePagamento && (
          <>
            <br></br>
            <PontualidadePagamento
              pontualidadePagamento={resposta.pontualidadePagamento}
            ></PontualidadePagamento>
          </>
        )}
        {resposta.historicoPagamento && (
          <>
            <br></br>
            <HistoricoPagamento
              historicoPagamento={resposta.historicoPagamento}
            ></HistoricoPagamento>
          </>
        )}
        {resposta.limiteCreditoPJ && (
          <>
            <br></br>
            <LimiteCreditoPJ
              limiteCreditoPJ={resposta.limiteCreditoPJ}
            ></LimiteCreditoPJ>
          </>
        )}
        {resposta.maisNegocio && (
          <>
            <br></br>
            <MaisNegocio maisNegocio={resposta.maisNegocio}></MaisNegocio>
          </>
        )}
        {resposta.faturamentosPresumido && (
          <>
            <br></br>
            <FaturamentoPresumido
              faturamentos={resposta.faturamentosPresumido}
            ></FaturamentoPresumido>
          </>
        )}
        {resposta.consulta && (
          <>
            <br></br>
            <ListaConsulta consulta={resposta.consulta}></ListaConsulta>
          </>
        )}
        {resposta.automotivaAgregado && (
          <>
            <br></br>
            <AutomotivaAgregado
              automotivaAgregado={resposta.automotivaAgregado}
            ></AutomotivaAgregado>
          </>
        )}
        {resposta.automotivaMotor && (
          <>
            <br></br>
            <AutomotivaMotor
              automotivaMotor={resposta.automotivaMotor}
            ></AutomotivaMotor>
          </>
        )}
        {resposta.automotivaPrecificador && (
          <>
            <br></br>
            <AutomotivaPrecificador
              automotivaPrecificador={resposta.automotivaPrecificador}
            ></AutomotivaPrecificador>
          </>
        )}
        {resposta.automotivaGravameSimples && (
          <>
            <br></br>
            <AutomotivaGravame
              automotivaGravame={resposta.automotivaGravameSimples}
            ></AutomotivaGravame>
          </>
        )}
        {resposta.automotivaRenajud && (
          <>
            <br></br>
            <AutomotivaRenajud
              automotivaRenajud={resposta.automotivaRenajud}
            ></AutomotivaRenajud>
          </>
        )}
        {resposta.automotivaBaseEstadual && (
          <AutomotivaBaseEstadual data={resposta.automotivaBaseEstadual} />
        )}
        {resposta.automotivaBaseRenavam && (
          <>
            <br></br>
            <AutomotivaRenavam
              automotivaRenavam={resposta.automotivaBaseRenavam}
            ></AutomotivaRenavam>
          </>
        )}
        {resposta.automotivaHistoricoProprietario && (
          <>
            <br></br>
            <AutomotivaHistoricoProprietario
              automotivaHistoricoProprietario={
                resposta.automotivaHistoricoProprietario
              }
            ></AutomotivaHistoricoProprietario>
          </>
        )}
        {resposta.automotivaLeilao && (
          <>
            <br></br>
            <AutomotivaLeilao
              automotivaLeilao={resposta.automotivaLeilao}
            ></AutomotivaLeilao>
          </>
        )}
        {resposta.automotivaOnline && (
          <>
            <br></br>
            <AutomotivaOnline
              automotivaOnline={resposta.automotivaOnline}
            ></AutomotivaOnline>
          </>
        )}
        {resposta.automotivaRenainf && (
          <>
            <br></br>
            <AutomotivaRenainf
              automotivaRenainf={resposta.automotivaRenainf}
            ></AutomotivaRenainf>
          </>
        )}
        {resposta.cadastralNomePF && (
          <>
            <br></br>
            <CadastralNomePF
              cadastralNomePF={resposta.cadastralNomePF}
            ></CadastralNomePF>
          </>
        )}
        {resposta.empresas && resposta.empresas.length > 0 && (
          <>
            <br></br>
            <Empresas empresas={resposta.empresas}></Empresas>
          </>
        )}
        {resposta.socios && resposta.socios.length > 0 && (
          <>
            <br></br>
            <Socios socios={resposta.socios}></Socios>
          </>
        )}
        {resposta.participacoesEmpresa &&
          resposta.participacoesEmpresa.length > 0 && (
            <>
              <br></br>
              <ParticipacaoEmpresa
                participacoes={resposta.participacoesEmpresa}
              ></ParticipacaoEmpresa>
            </>
          )}
        {resposta.enderecosHist && resposta.enderecosHist.length > 0 && (
          <>
            <br></br>
            <EnderecoHist enderecos={resposta.enderecosHist}></EnderecoHist>
          </>
        )}
        {resposta.emailsHist && resposta.emailsHist.length > 0 && (
          <>
            <br></br>
            <HistoricoInfo
              showHist={true}
              label={t("email")}
              historicos={resposta.emailsHist}
            ></HistoricoInfo>
          </>
        )}
        {resposta.conglomerados && resposta.conglomerados.length > 0 && (
          <>
            <br></br>
            <HistoricoInfo
              showHist={false}
              label={t("conglomerados")}
              historicos={resposta.conglomerados}
            ></HistoricoInfo>
          </>
        )}
        {resposta.calularesHist && resposta.calularesHist.length > 0 && (
          <>
            <br></br>
            <HistoricoInfo
              showHist={true}
              label={t("celular")}
              historicos={resposta.calularesHist}
            ></HistoricoInfo>
          </>
        )}
        {resposta.telefonesHist && resposta.telefonesHist.length > 0 && (
          <>
            <br></br>
            <HistoricoInfo
              showHist={true}
              label={t("fone")}
              historicos={resposta.telefonesHist}
            ></HistoricoInfo>
          </>
        )}
        {resposta.analises && resposta.analises.length > 0 && (
          <>
            <br></br>
            <Analise analises={resposta.analises}></Analise>
          </>
        )}
        {resposta.indicadoresPJ && resposta.indicadoresPJ.length > 0 && (
          <>
            <br></br>
            <IndicadorPJ
              indicadores={resposta.indicadoresPJ}
            ></IndicadorPJ>{" "}
          </>
        )}
        {resposta.indicadores && resposta.indicadores.length > 0 && (
          <>
            <br></br>
            <IndicadorNegocio
              indicadores={resposta.indicadores}
            ></IndicadorNegocio>{" "}
          </>
        )}
        {resposta.cpfCnpjTotalizadorProcessos && (
          <Credify data={resposta.cpfCnpjTotalizadorProcessos} />
        )}
        {resposta.autocorpLeilao && (
          <AutocorpLeilao data={resposta.autocorpLeilao} />
        )}
        {resposta.gps353 && <Gps353 data={resposta.gps353} />}
        {resposta.gps391 && <Gps391 data={resposta.gps391} />}
        {resposta.automotivaPrecificadorFipe && (
          <CredifyPrecificadorFipe data={resposta.automotivaPrecificadorFipe} />
        )}
        {resposta.cadastralTelefonePF && (
          <>
            {" "}
            <br></br>
            <CredifyPfTelefone data={resposta.cadastralTelefonePF} />
          </>
        )}
        {resposta.cadastralPesquisaPF && (
          <>
            {" "}
            <br></br>
            <CredifyConsultaCPF data={resposta.cadastralPesquisaPF} />
          </>
        )}
        {resposta.cadastralProprietarioTelefoneOperadora && (
          <>
            {" "}
            <br></br>
            <ProprietarioTelefoneOperadora
              data={resposta.cadastralProprietarioTelefoneOperadora}
            />
          </>
        )}
        {resposta.cadastralTelefonePJ && (
          <>
            {" "}
            <br></br>
            <CadastralTelefonePJ data={resposta.cadastralTelefonePJ} />
          </>
        )}
        {resposta.cadastralEmailPF && (
          <>
            {" "}
            <br></br>
            <CadastralPFEmail data={resposta.cadastralEmailPF} />
          </>
        )}
        {resposta.cadastralEmailPJ && (
          <>
            {" "}
            <br></br>
            <CadastralEmailPJ data={resposta.cadastralEmailPJ} />
          </>
        )}
        {resposta.autoVeiculoProprietario && (
          <>
            {" "}
            <br></br>
            <AutomotivaVeiculoProprietario
              {...resposta.autoVeiculoProprietario}
            />
          </>
        )}
        {resposta.karfexVeiculo && (
          <>
            {" "}
            <br></br>
            <KarfexVeiculo {...resposta.karfexVeiculo} />
          </>
        )}
        {resposta.historicoRouboFurto && (
          <>
            {" "}
            <br></br>
            <RouboFurto {...resposta.historicoRouboFurto} />
          </>
        )}
        {resposta.baseLeilao && (
          <>
            {" "}
            <br></br>
            <ConsultaBaseLeilao {...resposta.baseLeilao} />
          </>
        )}
        {resposta.cadastralDocumentoCNH && (
          <>
            {" "}
            <br></br>
            <ProdutoDocumentoCNH {...resposta.cadastralDocumentoCNH} />
          </>
        )}

        {resposta.autoCsv && (
          <>
            {" "}
            <br></br>
            <AutomotivaCSVPage {...resposta.autoCsv} />
          </>
        )}

        {resposta.autoCrlv && (
          <>
            {" "}
            <br></br>
            <AutomotivaCRLVPage {...resposta.autoCrlv} />
          </>
        )}

        {resposta.placaFipe && (
          <>
            {" "}
            <br></br>
            <AutomotivaFipePage {...resposta.placaFipe} />
          </>
        )}

        {resposta.automotivaleilaolocal && (
          <>
            {" "}
            <br></br>
            <AutomotivaLeilaoLocalPage {...resposta.automotivaleilaolocal} />
          </>
        )}

        {resposta.automotivatabelaconsultasinistro && (
          <>
            {" "}
            <br></br>
            <AutomotivaSinistroPage
              {...resposta.automotivatabelaconsultasinistro}
            />
          </>
        )}


        {resposta.automotivaalerta && (
          <>
            {" "}
            <br></br>
            <AutomotivaAlertaVeicular {...resposta.automotivaalerta} />
            </>
        )}
        {resposta.cadastralsrcbacenscorepositivo && (
          <>
            {" "}
            <br></br>
            <BacenScoreSCRPage {...resposta.cadastralsrcbacenscorepositivo} />
          </>
        )}

        <ScrollTop
          style={{ marginBottom: "5rem" }}
          className="w-2rem h-2rem border-round bg-primary"
          icon="pi pi-arrow-up text-base"
        />
        <br></br>
        <Message
          style={{ width: "98%" }}
          severity="warn"
          text={<RodapeMensagem />}
        />
      </div>
      <br></br>
      <br></br>
      <Toolbar style={{ width: "98%" }} start={startContent} end={endContent} />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}
