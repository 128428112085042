import Interface from "./Interface";
import { useGlobalState } from "../globalState/GlobalStateProvider";
import React, { useMemo } from "react";
import { Button } from "primereact/button";

type Props = {
  children: React.ReactNode;
};

const Container = ({ children }: Props) => {
  const stateGlobal = useGlobalState();
  const marginTop = useMemo(() => "56px", []);
  const marginLeft = useMemo(() => {
    return !!stateGlobal.state?.sidebarOpen ? "292px" : "92px";
  }, [stateGlobal]);

  return (
    <>
      <Interface />
      <div style={{ marginTop: marginTop, marginLeft: marginLeft }}>
        <div style={{ width: "98%" }}>
          {children}
          <Button
            className="button-whatsapp"
            icon="pi pi-whatsapp"
            severity="success"
            onClick={() => {
              window.open(
                "https://web.whatsapp.com/send?phone=+551936363599&text=Olá preciso de atendimento",
                "_blank"
              );
            }}
            style={{ position: "fixed", bottom: "2rem", right: "1.25rem" }}
          />
        </div>
      </div>
    </>
  );
};

export default Container;
